import { useEffect } from "react";
import classes from "../../../css/statuses/radioSilenceStatus.module.css";
import { useMqttConnectionContext } from "../../../context/AppContext";
import { Subscribe, Unsubscribe } from "../../../../mqtt/mqttService";
import { topicType } from "../../../../enums/topicType";
import { mqttConnectionStatus } from "../../../../enums/mqttConnectionStatus";
import config from "../../../../config";
import { getTopicName } from "../../../../helpers/getTopicName";
import ClockIcon from "../../../assets/icons/ClockIcon";
import { deviceStatusType } from "../../../../enums/deviceStatus";
import { devicesListDictionary } from "../../../../dictionary/ua/devicesListDictionary";
import { Tooltip } from "@mui/material";
import RadioSilenceIcon from "../../../assets/icons/RadioSilenceIcon";

const RadioSilenceStatus = ({ device }) => {
  const { connectionStatus } = useMqttConnectionContext();
  const getTooltipTitle = (date) => {
    if (!isNaN(Date.parse(date))) {
      return `${
        devicesListDictionary.lastUpdatedStatusText
      } ${date.toLocaleString(config.localization.ua)}`;
    }

    return "";
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (connectionStatus === mqttConnectionStatus.isConnected) {
        Subscribe(
          getTopicName(device.clientId, topicType.radioSilenceStatus),
          1
        );
      }
    }, config.subscribeIntervalMilliSeconds);

    setTimeout(() => {
      if (connectionStatus === mqttConnectionStatus.isConnected) {
        Subscribe(
          getTopicName(device.clientId, topicType.radioSilenceStatus),
          1
        );
      }
    }, config.subscribeTimeoutMilliSeconds);

    return () => {
      clearInterval(interval);
      setTimeout(() => {
        if (connectionStatus === mqttConnectionStatus.isConnected) {
          Unsubscribe(
            getTopicName(device.clientId, topicType.radioSilenceStatus),
            1
          );
        }
      }, config.subscribeTimeoutMilliSeconds);
    };
  }, [device.clientId, connectionStatus]);

  return (
    <Tooltip
      title={getTooltipTitle(device.radioSilenceStatusLastUpdated)}
      arrow
      placement="bottom"
    >
      <div className={classes["device-status-wrapper"]}>
        {device.radioSilenceStatus !== deviceStatusType.notApplicable &&
        device.radioSilenceStatus > 0 ? (
          <div className={classes["radio-silence-wrapper"]}>
            <span className={classes["radio-silence-icon-wrapper"]}>
              <RadioSilenceIcon />
            </span>
            <span>
              <ClockIcon className />
            </span>
            <span>{device.radioSilenceStatus}</span>
            <span>{devicesListDictionary.radioSilenceMinutesText}</span>
          </div>
        ) : (
          <div className={classes["radio-silence-wrapper"]}>
            <span>
              <ClockIcon className />
            </span>
            <span>{devicesListDictionary.zeroMinutesText}</span>
          </div>
        )}
      </div>
    </Tooltip>
  );
};

export default RadioSilenceStatus;
