export const editScheduleDictionary = {
    titleLabelText: "Назва",
    descriptionLabelText: "Опис",    
    editScheduleButtonText: "Оновити сценарій",
    scheduleNotUpdatedErrorText: "Не вдалось оновити сценарій...",
    shareScheduleButtonText: "Поділитись",
    stopSharingButtonText: "Заборонити доступ",
    privateScheduleMenuItemText: "Приватний",
    sharedScheduleMenuItemText: "Публічний",
    returnToScheduling: "Повернутись до сценаріїв"
}