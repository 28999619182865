import React, { useEffect, useState } from "react";
import classes from "../../css/pages/mqtt-settings/mqttSettings.module.css";
import { useMqttConnectionContext } from "../../context/AppContext";
import { Connect } from "../../../mqtt/mqttService";
import config from "../../../config";
import { mqttConnectionStatus } from "../../../enums/mqttConnectionStatus";
import { mqttConnectSchema } from "../../../validation/validationSchemas";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import CheckIcon from "@mui/icons-material/Check";
import ConnectionStatus from "./ConnectionStatus";
import checkIconClasses from "../../css/assets/icons/checkIcon.module.css";
import { errorType } from "../../../enums/errorType";
import { mqttConnectDictionary } from "../../../dictionary/ua/mqttConnectDictionary";
import { documentTitleDictionary } from "../../../dictionary/ua/documentTitleDictionary";
import {
  Checkbox,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  OutlinedInput,
} from "@mui/material";

import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import visibilityIconClasses from "../../css/assets/icons/visibilityIcon.module.css";
import { useNavigate } from "react-router-dom";
import { publish } from "../../../events/event";
import { mqttConnectionLostEventType } from "../../../enums/eventType";
import { links } from "../../../enums/links";
import { mqttSettingsApi } from "../../../api/mqttSettingsApi";
import Button from "../../assets/buttons/Button";
import { refreshPage } from "../../../helpers/windowOperations";
import buttonClasses from "../../css/assets/buttons/button.module.css";
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import SaveIcon from '@mui/icons-material/Save';
import useDocumentTitle from "../../hooks/useDocumentTitle";
import useConnectToMqtt from "../../hooks/useConnectToMqtt";

function MqttSettings({ cookies }) {
  useDocumentTitle(documentTitleDictionary.mqttSettingsTitle + " - " + documentTitleDictionary.mainTitle);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    getFieldState,
    reset,
    formState: { errors, isValid, isDirty, isSubmitSuccessful },
  } = useForm({
    resolver: yupResolver(mqttConnectSchema),
    mode: "all",
  });

  const [showPassword, setValues] = useState(false);
  const [isCapsLockOn, setIsCapsLockOn] = useState(false);
  const [pressed, setPressed] = useState(false);
  const [mqttSettings, setMqttSettings] = useState({
    id: "",
    host: "",
    port: "",
    username: "",
    password: "",
    tls: false
  });

  const isAuthenticated = () => {
    return cookies.get("token")?.token ? true : false;
  }

  const getUserId = () => {
    return cookies.get("token")?.userId;
  }

  const setPressedClass = () => {
    return pressed === true ? "pressed" : "";
  };

  const handleMouseDown = () => {
    setPressed(true);
    document.addEventListener("mouseup", () => handleMouseUp(), { once: true });
  };

  const handleMouseUp = () => {
    setPressed(false);
  };

  const { updateConnectionStatus } = useMqttConnectionContext();
  const onSubmit = async () => {
    try {
      await Connect(
        mqttSettings.host,
        parseInt(mqttSettings.port),
        config.mqttConfig.myClientId,
        config.mqttConfig.cleanSession,
        onErrorHandler,
        onConnectedHandler,
        onConnectionLostHandler
      ).then(() => {
        updateConnectionStatus(mqttConnectionStatus.isConnecting);
      });
    } catch (error) {
      onErrorHandler(error);
    }
  };

  const onConnectedHandler = (e) => {
    updateConnectionStatus(mqttConnectionStatus.isConnected);
  };

  const onConnectionLostHandler = (e) => {
    publish(mqttConnectionLostEventType.mqttConnectionLost);
    updateConnectionStatus(mqttConnectionStatus.isNotConnected);
  };

  const onErrorHandler = (error) => {
    switch (error) {
      case errorType.mqttConnectionError:
        updateConnectionStatus(mqttConnectionStatus.isNotConnected);
        break;
      default:
    }
  };

  const handleChange = (event) => {
    setMqttSettings((prevState) => {
      return {
        ...prevState,
        [event.target.name]: event.target.value
      };
    });
  };

  const handleTlsChange = (e) => {
    setMqttSettings((prevState) => {
      return {
        ...prevState,
        tls: !prevState.tls,
      };
    });
  };

  const handleClickShowPassword = () => {
    setValues((prevState) => !prevState);
  };

  const checkCapsLock = (event) => {
    if (event.getModifierState("CapsLock")) {
      setIsCapsLockOn(true);
    } else {
      setIsCapsLockOn(false);
    }
  };

  const handleClickUpdateMqttSettings = (event) => {
    event.preventDefault();
    mqttSettingsApi.update(mqttSettings)
    .then((response) => {
      if(response !== true) {
        //log: "failed to update mqtt settings for user"
      }
    }).catch(function(error) {
      //log error
    })
  }

  const handleClickRestoreDefaultMqttSettings = (event) => {
    event.preventDefault();
    mqttSettingsApi
      .restoreDefaultSettingsByUserId(getUserId())
      .then((response) => {
        if (response.success === true) {
          setMqttSettings({
            id: response.result.id,
            host: response.result.host,
            port: response.result.port,
            username: response.result.username,
            password: response.result.password,
            tls: response.result.tls,
          });
        }
      })
      .catch(function (error) {
        //log error
      });
  };  

  useConnectToMqtt(getUserId(), isAuthenticated());

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset(
        {
          host: mqttSettings.host,
          port: mqttSettings.port,
          username: mqttSettings.username,
          password: mqttSettings.password,
        },
        { keepDefaultValues: false }
      );
    }

    mqttSettingsApi
      .getByUserId(getUserId())
      .then((response) => {
        setMqttSettings({
          id: response.id,
          host: response.host,
          port: response.port,
          username: response.username,
          password: response.password,
          tls: response.tls,
        });
      })
      .catch(function (error) {
        //log error
      });
   
    const interval = setInterval(() => {
      if (!isAuthenticated()) {
        navigate(links.loginPagePath);
        refreshPage();
      }
    }, config.checkCookieExpirationMilliseconds);

    return () => {
      clearInterval(interval);
    };
  }, [isSubmitSuccessful, reset]);

  return (
    isAuthenticated() && (
      <div className={classes["mqtt-connect"]}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={classes["form-control-container"]}>
            <span>
              <label htmlFor="host">{mqttConnectDictionary.hostLabel}</label>
            </span>
            <span className={classes["input-container"]}>
              <FormControl
                className={classes["form-control"]}
                error={getFieldState("host").invalid}
                onChange={handleChange}
              >
                <OutlinedInput
                  id="host"
                  type="text"
                  name="host"
                  size="small"
                  {...register("host")}
                  value={mqttSettings.host ?? ""}
                />
                <FormHelperText>{errors?.host?.message}</FormHelperText>
              </FormControl>
              {getFieldState("host").isDirty &&
                !getFieldState("host").invalid && (
                  <CheckIcon
                    className={checkIconClasses["connection-status-icon"]}
                  />
                )}
            </span>
          </div>

          <div className={classes["form-control-container"]}>
            <span>
              <label htmlFor="port">{mqttConnectDictionary.portLabel}</label>
            </span>
            <span className={classes["input-container"]}>
              <FormControl
                className={classes["form-control"]}
                error={getFieldState("port").invalid}
                onChange={handleChange}
              >
                <OutlinedInput
                  id="port"
                  type="number"
                  name="port"
                  size="small"
                  {...register("port")}
                  value={mqttSettings.port ?? ""}
                />
                <FormHelperText>{errors?.port?.message}</FormHelperText>
              </FormControl>
              {getFieldState("port").isDirty &&
                !getFieldState("port").invalid && (
                  <CheckIcon
                    className={checkIconClasses["connection-status-icon"]}
                  />
                )}
            </span>
          </div>

          <div className={classes["form-control-container"]}>
            <span>
              <label htmlFor="username">
                {mqttConnectDictionary.usernameLabel}
              </label>
            </span>
            <span className={classes["input-container"]}>
              <FormControl
                className={classes["form-control"]}
                error={getFieldState("username").invalid}
                onChange={handleChange}
              >
                <OutlinedInput
                  id="username"
                  type="text"
                  name="username"
                  size="small"
                  {...register("username")}
                  value={mqttSettings.username ?? ""}
                />
                <FormHelperText>{errors?.username?.message}</FormHelperText>
              </FormControl>
              {!getFieldState("username").invalid && (
                <CheckIcon
                  className={checkIconClasses["connection-status-icon"]}
                />
              )}
            </span>
          </div>

          <div className={classes["form-control-container"]}>
            <span>
              <label htmlFor="password">
                {mqttConnectDictionary.passwordLabel}
              </label>
            </span>
            <span className={classes["input-container"]}>
              <FormControl
                className={classes["form-control"]}
                error={getFieldState("password").invalid}
                onChange={handleChange}
              >
                <OutlinedInput
                  id="password"
                  type={showPassword ? "text" : "password"}
                  name="password"
                  size="small"
                  value={mqttSettings.password ?? ""}
                  {...register("password")}
                  onKeyUp={checkCapsLock}
                  endAdornment={
                    <InputAdornment
                      className={classes["input-adornment"]}
                      position="end"
                    >
                      <IconButton onClick={handleClickShowPassword}>
                        {showPassword ? (
                          <div
                            className={
                              visibilityIconClasses["visibility-icon-wrapper"]
                            }
                          >
                            <VisibilityIcon
                              className={
                                visibilityIconClasses["visibility-icon"]
                              }
                            />
                          </div>
                        ) : (
                          <div
                            className={
                              visibilityIconClasses["visibility-icon-wrapper"]
                            }
                          >
                            <VisibilityOffIcon
                              className={
                                visibilityIconClasses["visibility-icon"]
                              }
                            />
                          </div>
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                <FormHelperText>{errors?.password?.message}</FormHelperText>
              </FormControl>
              {!getFieldState("password").invalid && (
                <CheckIcon
                  className={checkIconClasses["connection-status-icon"]}
                />
              )}
            </span>
            <span>
              <FormHelperText
                sx={{ color: "#ff9966" }}
                className={classes["warning-text"]}
              >
                {isCapsLockOn && (
                  <span>{mqttConnectDictionary.capsLockIsOnText}</span>
                )}
              </FormHelperText>
            </span>
          </div>

          <div className={classes["form-control-container"]}>
            <span className={classes["tls-checkbox-container"]}>
              <label>{mqttConnectDictionary.tlsLabel}</label>
              <Checkbox
                {...register("tls")}
                checked={mqttSettings.tls}
                onChange={handleTlsChange}
              />
            </span>
          </div>

          <div className={classes["connection-container"]}>
            <ConnectionStatus
              host={mqttSettings.host}
              port={mqttSettings.port}
              isConnectButtonDisabled={false}
            />
            <div>
              <Button
                className={`${buttonClasses["save-current-settings-button"]} ${
                  buttonClasses[setPressedClass()]
                }`}
                icon={<SaveIcon />}
                startIcon="start"
                onClick={handleClickUpdateMqttSettings}
                onMouseDown={handleMouseDown}
                onMouseUp={handleMouseUp}
              >
                {mqttConnectDictionary.saveCurrentMqttSettings}
              </Button>
              <Button
                className={`${
                  buttonClasses["restore-default-settings-button"]
                } ${buttonClasses[setPressedClass()]}`}
                icon={<SettingsBackupRestoreIcon />}
                startIcon="start"
                onClick={handleClickRestoreDefaultMqttSettings}
                onMouseDown={handleMouseDown}
                onMouseUp={handleMouseUp}
              >
                {mqttConnectDictionary.restoreDefaultMqttSettings}
              </Button>
            </div>
          </div>
        </form>
      </div>
    )
  );
}

export default MqttSettings;
