import classes from "../../../../css/pages/scheduling/public-schedules/scheduleRow.module.css";
import { FormHelperText, IconButton, Tooltip } from "@mui/material";
import { schedulingDictionary } from "../../../../../dictionary/ua/scheduling/schedulingDictionary";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import { scheduleApi } from "../../../../../api/scheduleApi";
import { useState } from "react";
import { addScheduleErrors } from "../../../../../enums/errorMappers/addScheduleErrors";

const ScheduleRow = ({ schedule, setOwnedScheduleList, username }) => {
  const [apiErrors, setApiErrors] = useState([]);
  const handleCopyScheduleClick = () => {
    let payload = {
      name: schedule.name,
      description: schedule.description,
      scheduleString: schedule.scheduleString,
      creatorUsername: username,
    };

    scheduleApi.create(payload).then((response) => {
      if (response.success === true) {
        setOwnedScheduleList((prevState) => {
          payload.id = response.result.id;
          return [payload, ...prevState];
        });
      } else {
        setApiErrors([addScheduleErrors.FailedToCreateSchedule]);
      }
    });
  };

  return (
    <div className={`${classes["row-wrapper"]}`}>
      <div className={`${classes["wrapped-area"]}`}>
        <div className={classes["row"]}>
          <div className={classes["copy-schedule-column"]}>
            <Tooltip
              title={schedulingDictionary.copyScheduleTooltipText}
              arrow
              placement="top"
            >
              <IconButton
                className={classes["copy-schedule-icons"]}
                onClick={handleCopyScheduleClick}
              >
                <ArrowBackIcon />
                <FileCopyIcon />
                {apiErrors.map((e) => (
                  <FormHelperText key={e.errorCode}>
                    {e.errorText}
                  </FormHelperText>
                ))}
              </IconButton>
            </Tooltip>
          </div>

          <div className={classes["title-column"]}>{schedule.name}</div>
          <div className={classes["description-column"]}>
            {schedule.description}
          </div>
          <div className={classes["scheduleString-column"]}>
            {schedule.scheduleString}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScheduleRow;
