import config from "../config";
import { deviceStatusType } from "../enums/deviceStatus";

export const dateTimeHelper = {
  getLocalDateTime: (dateTime) => new Date(dateTime).toLocaleString(config.localization.ua),
  getLatestDateTime: (dateTimeArray) => {
    if (!Array.isArray(dateTimeArray) || dateTimeArray.length === 0) {
      return null;
    }

    const validDates = dateTimeArray
      .filter((date) => date && date !== deviceStatusType.notApplicable)
      .map((date) => new Date(date))
      .filter((date) => !isNaN(date));

    if (validDates.length === 0) {
      return null;
    }

    let latestDateTime = validDates[0];
    for (let i = 1; i < validDates.length; i++) {
      if (validDates[i] > latestDateTime) {
        latestDateTime = validDates[i];
      }
    }

    return latestDateTime.toISOString();
  },
};
