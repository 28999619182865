import React, { useEffect, useState } from "react";
import classes from "../css/commands/schedulesCommand.module.css";
import { Publish } from "../../mqtt/mqttService";
import { useMqttConnectionContext } from "../context/AppContext";
import { topicType } from "../../enums/topicType";
import { mqttConnectionStatus } from "../../enums/mqttConnectionStatus";
import { getTopicName } from "../../helpers/getTopicName";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { scheduleCommandInputSchema } from "../../validation/validationSchemas";
import { FormControl, FormHelperText, MenuItem, Select } from "@mui/material";
import Button from "../assets/buttons/Button";
import buttonClasses from "../css/assets/buttons/button.module.css";
import SendIcon from "../assets/icons/SendIcon";
import { mqttConnectDictionary } from "../../dictionary/ua/mqttConnectDictionary";
import { devicesListDictionary } from "../../dictionary/ua/devicesListDictionary";
import dayjs from "dayjs";

function SchedulesCommand({ selectedDevicesClientIds, scheduleList }) {
  const [notValidationErrorText, setNotValidationError] = useState("");
  const [pressed, setPressed] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const { connectionStatus } = useMqttConnectionContext();
  const [selectedSchedule, setSelectedSchedule] = useState({
    id: "",
    name: "",
    description: "",
    scheduleString: "",
    userId: ""
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(scheduleCommandInputSchema),
    mode: "onChange",
  });

  const onSubmit = () => {
    if (connectionStatus !== mqttConnectionStatus.isConnected) {
      setNotValidationError(mqttConnectDictionary.disconnectedFromMqttText);
      return;
    } else {
      setNotValidationError("");
    }
    
    setSelectedSchedule({
      id: "",
      name: "",
      description: "",
      scheduleString: "",
      userId: "",
    });

    setDisabled(true);

    const currentDateTime = dayjs().format('YYYY-MM-DDTHH:mm:ssZ[Z]');
    selectedDevicesClientIds.map(clientId => {
      Publish(
        getTopicName(clientId, topicType.scheduleCommand),
        `CRC=0X12345678;TIMESTAMP=1111111111111111;${selectedSchedule.scheduleString}END;`,
        1,
        false,
        false
      );

      return clientId;
    });    
  };
  
  const setPressedClass = () => {
    return pressed === true ? "pressed" : "";
  };

  const handleMouseDown = () => {
    setPressed(true);
    document.addEventListener("mouseup", () => handleMouseUp(), { once: true });
  };

  const handleMouseUp = () => {
    setPressed(false);
  };

  const handleChange = (event) => {
    if (selectedSchedule && isAnyDeviceSelected()) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }

    setSelectedSchedule(
      scheduleList?.find((schedule) => schedule.id === event.target.value)
    );
  };

  const isAnyDeviceSelected = () => selectedDevicesClientIds?.length > 0;

  useEffect(() => {    
    setDisabled(selectedSchedule.id === "" || !isAnyDeviceSelected());
}, [isAnyDeviceSelected]);

  return (
    <div className={classes["command-wrapper"]}>
      <span>Сценарій</span>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={classes["input-container"]}>
          <FormControl className={classes["form-control"]}>
            <Select
              className={classes["schedule-select"]}
              value={selectedSchedule.id}
              size="small"
              {...register("schedule")}
              onChange={handleChange}
            >
              {scheduleList.length > 0 &&
                scheduleList.map((schedule) => (
                  <MenuItem key={schedule.id} value={schedule.id}>
                    <div className={classes["schedule-wrapper"]}>
                      <span>{schedule.name}</span>
                      <span>{schedule.description}</span>
                    </div>
                  </MenuItem>
                ))}
            </Select>
            <FormHelperText>{errors?.schedule?.message}</FormHelperText>
          </FormControl>
          <FormHelperText className={classes["not-validation-error-text"]}>
            {notValidationErrorText}
          </FormHelperText>

          <Button
            className={`${buttonClasses["schedule-button"]} ${
              buttonClasses[setPressedClass()]
            }`}
            disabled={disabled}
            icon={<SendIcon />}
            startIcon="start"
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
          >
            {devicesListDictionary.scheduleButtonText}
          </Button>
        </div>
      </form>
    </div>
  );
}

export default SchedulesCommand;
