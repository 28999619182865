import classes from "../../../css/assets/dropdowns/profiles/wifi.module.css";

const WifiProfile = ({children}) => {
  return (
    <div className={classes.wrapper}>
      <span className={classes.text}>{children}</span>
    </div>
  );
};

export default WifiProfile;
