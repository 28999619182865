import { IconButton } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';

const DeleteDeviceButton = ({onClick}) => {
  return (
    <IconButton onClick={onClick}>
      <DeleteIcon />
    </IconButton>
  );
};

export default DeleteDeviceButton;
