export const documentTitleDictionary = {
    mainTitle: "ІХП",
    mqttSettingsTitle: "Налаштування MQTT",
    deviceListTitle: "Пристрої",
    devicesTitle: "Перелік пристроїв",
    loginTitle: "Авторизація",
    schedulingTitle: "Дизайнер сценаріїв",
    editScheduleTitle: "Редагувати сценарій",
    editDeviceTitle: "Редагувати пристрій"
}
