import React, { useEffect, useState } from "react";
import classes from "../css/commands/relay.module.css";
import { Publish } from "../../mqtt/mqttService";
import { useMqttConnectionContext } from "../context/AppContext";
import { topicType } from "../../enums/topicType";
import { mqttConnectionStatus } from "../../enums/mqttConnectionStatus";
import { getTopicName } from "../../helpers/getTopicName";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import buttonClasses from "../css/assets/buttons/button.module.css";
import Button from "../assets/buttons/Button";
import ConfirmIcon from "../assets/icons/ConfirmIcon";
import { FormControl, FormHelperText, MenuItem, Select } from "@mui/material";
import { mqttConnectDictionary } from "../../dictionary/ua/mqttConnectDictionary";
import { devicesListDictionary } from "../../dictionary/ua/devicesListDictionary";
import { relayCommandInputSchema } from "../../validation/validationSchemas";
import OffRelay from "../assets/relay/OffRelay";
import OnRelay from "../assets/relay/OnRelay";

function FdoCommand({selectedDevicesClientIds}) {
  const [notValidationErrorText, setNotValidationError] = useState("");
  const [relay, setRelay] = useState("");
  const [pressed, setPressed] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const { connectionStatus } = useMqttConnectionContext();
  const {
    register,
    handleSubmit,
    getFieldState,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(relayCommandInputSchema),
    mode: "onChange",
  });

  const onSubmit = (data) => {
    if (connectionStatus !== mqttConnectionStatus.isConnected) {
        setNotValidationError(mqttConnectDictionary.disconnectedFromMqttText);
        return;
      } else {
        setNotValidationError("");
      }
  
      setRelay("");
      setDisabled(true);
      selectedDevicesClientIds.map(clientId => {
        Publish(
          getTopicName(clientId, topicType.fdoCommand),
          data.relay,
          1,
          false,
          false
        );
  
        return clientId;
      });   
  };

  const setPressedClass = () => {
    return pressed === true ? "pressed" : "";
  };

  const handleChange = (event) => {
    if (relay && isAnyDeviceSelected()) {
        setDisabled(false);
      }
      else {
        setDisabled(true);
      }
  
      setRelay(event.target.value);
  };

  const handleMouseDown = () => {
    setPressed(true);
    document.addEventListener("mouseup", () => handleMouseUp(), { once: true });
  };

  const handleMouseUp = () => {
    setPressed(false);
  };

  const isError = () => {
    return getFieldState("relay").invalid || notValidationErrorText !== "";
  }

  const isAnyDeviceSelected = () => selectedDevicesClientIds?.length > 0;

  useEffect(() => {    
    setDisabled(relay === "" || !isAnyDeviceSelected());
}, [isAnyDeviceSelected]);

  return (
    <div className={classes.command}>
      <span>{devicesListDictionary.fdoDropDownLabel}</span>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={classes["input-container"]}>
          <FormControl
            className={classes["form-control"]}
            error={isError()}
          >
            <Select
              id="relay"
              className={classes["relay-select"]}
              value={relay}
              size="small"
              {...register("relay")}
              onChange={handleChange}
            >
              <MenuItem value={0}>
                <OffRelay>{devicesListDictionary.offRelayText}</OffRelay>
              </MenuItem>
              <MenuItem value={1}>
                <OnRelay>{devicesListDictionary.onRelayText}</OnRelay>
              </MenuItem>
            </Select>
            <FormHelperText>{errors?.relay?.message}</FormHelperText>
          </FormControl>
          <FormHelperText className={classes["not-validation-error-text"]}>{notValidationErrorText}</FormHelperText>
          <Button
            className={`${buttonClasses["relay-button"]} ${
              buttonClasses[setPressedClass()]
            }`}
            icon={<ConfirmIcon />}
            startIcon="start"
            disabled={disabled}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
          >
            {devicesListDictionary.relayButtonText}
          </Button>
        </div>
      </form>
    </div>
  );
}

export default FdoCommand;
