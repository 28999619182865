export const links = {
    connectToMqttLink: "mqtt-settings",
    addDeviceLink: "add-device",
    deviceListLink: "device-list",
    devicesLink: "devices",
    logOutLink: "logout",    
    schedulingLink: "scheduling",
    connectToMqttPath: "/mqtt-settings",
    addDevicePath: "/add-device",
    deviceListPath: "/device-list",
    devicesPath: "/devices",
    loginPagePath: "/login",
    logOutPath: "/logout",
    schedulingPath: "/scheduling",
    scheduleEditLink: (scheduleId) => `../schedule/${scheduleId}`,
    scheduleEditPath: "/schedule",
    deviceEditLink: (deviceId) => `../device/${deviceId}`,
    deviceEditPath: "/device",
    onlineStatusesLink: "/onlineStatuses",
    onlineStatusesPath: "onlineStatuses",
  };
  