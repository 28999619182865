import React, { useEffect } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { refreshPage } from "../../../helpers/windowOperations";

function Logout() {
  const [cookies, setCookie, removeCookies] = useCookies();
  const navigate = useNavigate();

  useEffect(() => {
    removeCookies("token");
    navigate("/login");
    refreshPage();
  }, []);

  return <></>;
}

export default Logout;
