import { useEffect, useState } from "react";
import classes from "../../css/pages/devices-list/deviceList.module.css";
import { deviceApi } from "../../../api/deviceApi";
import { deviceStatusType } from "../../../enums/deviceStatus";
import { devicesListDictionary } from "../../../dictionary/ua/devicesListDictionary";
import { documentTitleDictionary } from "../../../dictionary/ua/documentTitleDictionary";
import { useNavigate } from "react-router-dom";
import { links } from "../../../enums/links";
import config from "../../../config";
import { refreshPage } from "../../../helpers/windowOperations";
import useConnectToMqtt from "../../hooks/useConnectToMqtt";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import { scheduleApi } from "../../../api/scheduleApi";
import DeviceHeaderPicker from "./DeviceHeaderPicker";
import DeviceRowPicker from "./DeviceRowPicker";
import ControlPanelPicker from "./ControlPanelPicker";
import { deviceProfileMapper } from "../../../enums/deviceProfile";

const DeviceList = ({ cookies }) => {
  useDocumentTitle(documentTitleDictionary.deviceListTitle + " - " + documentTitleDictionary.mainTitle);
  const navigate = useNavigate();
  const [deviceList, setDeviceList] = useState([]);
  const setDeviceListWrapper = (newState) => {
    setDeviceList(newState);
  };

  const [scheduleList, setScheduleList] = useState([]);
  const getSelectAllDevicesCheckboxIndicator = () => {
    const result = {};
    Object.keys(deviceList).forEach((profile) => {
      const selectedCount = deviceList[profile].array.filter(
        (item) => item.selectedForSendingMessage
      ).length;

      result[profile] =
        selectedCount === deviceList[profile].array.length;
    });

    return result;
  };

  const getSelectedDevicesClientIds = () => {
    const result = {};
    Object.keys(deviceList).forEach((profile) => {
      const selectedClientIds = deviceList[profile].array.filter(
        (item) => item.selectedForSendingMessage
      ).map(item => item.clientId);

      result[profile] = selectedClientIds;
    });
    return result;
  }

  const isAuthenticated = () => {
    return cookies.get("token")?.token ? true : false;
  }

  const getUserId = () => {
    return cookies.get("token")?.userId;
  }

  const getUsername = () => {
    return cookies.get("token")?.username;
  }

  const fetchDeviceList = () => {
    deviceApi.getAllWithStateByUserId(getUserId())
      .then((response) => {        
        let devicesListWithState = response.map((d) => ({
          ...d,
          batteryVoltageLevelStatus: deviceStatusType.notApplicable,
          batteryVoltageLevelStatusLastUpdated: deviceStatusType.notApplicable,
          gsm1SignalLevelStatus: deviceStatusType.notApplicable,
          gsm1SignalLevelStatusLastUpdated: deviceStatusType.notApplicable,
          gsm2SignalLevelStatus: deviceStatusType.notApplicable,
          gsm2SignalLevelStatusLastUpdated: deviceStatusType.notApplicable,
          gsm3SignalLevelStatus: deviceStatusType.notApplicable,
          gsm3SignalLevelStatusLastUpdated: deviceStatusType.notApplicable,
          gsmAudioModeStatus: deviceStatusType.notApplicable,
          gsmAudioModeStatusLastUpdated: deviceStatusType.notApplicable,
          radioAudioModeStatus: deviceStatusType.notApplicable,
          radioAudioModeStatusLastUpdated: deviceStatusType.notApplicable,
          radioSilenceStatus: deviceStatusType.notApplicable,
          radioSilenceStatusLastUpdated: deviceStatusType.notApplicable,
          scheduleStatus: deviceStatusType.notApplicable,
          scheduleStatusLastUpdated: deviceStatusType.notApplicable,
          gsm1BalanceStatus: deviceStatusType.notApplicable,
          gsm1BalanceStatusLastUpdated: deviceStatusType.notApplicable,
          gsm2BalanceStatus: deviceStatusType.notApplicable,
          gsm2BalanceStatusLastUpdated: deviceStatusType.notApplicable,
          gsm3BalanceStatus: deviceStatusType.notApplicable,
          gsm3BalanceStatusLastUpdated: deviceStatusType.notApplicable,
          selectedForSendingMessage: false    
        }));

        setDeviceList(groupDevicesByProfile(devicesListWithState));
      })
      .catch(function (error) {
        //log error
      });
  };

  const fetchScheduleList = (search) => {
    scheduleApi
      .getOnlyOwnedByUser(getUsername(), search)
      .then((response) => {
        setScheduleList(response);
      })
      .catch(function (error) {
        //log error
      });
  };

  const groupDevicesByProfile = (devices) => {
    return devices.reduce((grouped, device) => {
      const profile = device.profile;
      grouped[profile] = grouped[profile] || { array: [], selectedClientIds: [] };
      grouped[profile].array.push(device);
      return grouped;
    }, {});
  };

  useConnectToMqtt(getUserId(), isAuthenticated());
  useEffect(() => {
    if (isAuthenticated()) {
      fetchDeviceList();
      fetchScheduleList("");
    }

    const interval = setInterval(() => {
      if (!isAuthenticated()) {
        navigate(links.loginPagePath);
        refreshPage();
      }
    }, config.checkCookieExpirationMilliseconds);

    return () => {
      clearInterval(interval);
    }
  }, []);

  if ((Object.keys(deviceList).length = 0))
    return <h2>{devicesListDictionary.noAvailableDeviceText}</h2>;

  return (
    isAuthenticated() && (
      <>
        {Object.keys(deviceList).map((profile) => (
          <div key={profile} className={classes["profile-container"]}>
            <div className={classes["header-container"]}>
              <div className={classes["aside-for-header"]}></div>
              <div className={classes["device-header"]}>
                <DeviceHeaderPicker profile={profile} />
              </div>
              <div className={classes["aside-for-header"]}></div>
            </div>

            <div className={classes["body-container"]}>
              <div className={classes["aside-for-body"]}></div>
              <div className={classes["device-row"]}>
                <span className={classes["profile-name"]}>
                  <h4>{deviceProfileMapper[profile]}</h4>
                </span>
                <div className={classes["rows-container"]}>
                  {deviceList[profile].array.length > 0 &&
                    deviceList[profile].array.map((device) => (
                      <DeviceRowPicker
                        key={device.id}
                        device={device}
                        setDeviceList={setDeviceListWrapper}
                      />
                    ))}
                </div>
                <ControlPanelPicker
                  profile={profile}
                  selectedDevicesClientIds={getSelectedDevicesClientIds()}
                  setDeviceList={setDeviceListWrapper}
                  scheduleList={scheduleList}
                  selectAllDevicesCheckboxIndicator={getSelectAllDevicesCheckboxIndicator()}
                />
              </div>
              <div className={classes["aside-for-body"]}> </div>
            </div>
          </div>
        ))}
      </>
    )
  );
};

export default DeviceList;
