import { deviceStatusEventType } from "../enums/eventType";
import { publish } from "./event";

export const deviceStatusEventPublisher = {
  publishRadioSilenceStatusChangedEvent: (content) => {
    publish(deviceStatusEventType.radioSilenceStatusChanged, content);
  },
  publishGsmAudioModeStatusChangedEvent: (content) => {
    publish(deviceStatusEventType.gsmAudioModeStatusChanged, content);
  },
  publishRadioAudioModeStatusChangedEvent: (content) => {
    publish(deviceStatusEventType.radioAudioModeStatusChanged, content);
  },
  publishScheduleStatusChangedEvent: (content) => {
    publish(deviceStatusEventType.scheduleStatusChanged, content);
  },
  publishBatteryVoltageLevelStatusChangedEvent: (content) => {
    publish(deviceStatusEventType.batteryVoltageLevelStatusChanged, content);
  },
  publishGsm1SignalLevelStatusChangedEvent: (content) => {
    publish(deviceStatusEventType.gsm1SignalLevelStatusChanged, content);
  },
  publishGsm2SignalLevelStatusChangedEvent: (content) => {
    publish(deviceStatusEventType.gsm2SignalLevelStatusChanged, content);
  },
  publishGsm3SignalLevelStatusChangedEvent: (content) => {
    publish(deviceStatusEventType.gsm3SignalLevelStatusChanged, content);
  },
  publishLastWillStatusChangedEvent: (clientId) => {
    publish(deviceStatusEventType.lastWillStatusChanged, clientId);
  },
  publishGsm1BalanceStatusChangedEvent: (content) => {
    publish(deviceStatusEventType.gsm1BalanceStatusChanged, content);
  },
  publishGsm2BalanceStatusChangedEvent: (content) => {
    publish(deviceStatusEventType.gsm2BalanceStatusChanged, content);
  },
  publishGsm3BalanceStatusChangedEvent: (content) => {
    publish(deviceStatusEventType.gsm3BalanceStatusChanged, content);
  },
  publishRelayStatusChangedEvent: (content) => {
    publish(deviceStatusEventType.relayStatusChanged, content);
  },
  publishGsmGateGsmBalanceStatusChangedEvent: (content) => {
    publish(deviceStatusEventType.gsmGateGsmBalanceStatusChanged, content);
  },
  publishGsmGateGsmSignalLevelStatusChangedEvent: (content) => {
    publish(deviceStatusEventType.gsmGateGsmSignalLevelStatusChanged, content);
  },
  publishGsmGateDIStatusChangedEvent: (content) => {
    publish(deviceStatusEventType.gsmGateDIStatusChanged, content);
  },
  publishGsmGatePhoneNumberStatusChangedEvent: (content) => {
    publish(deviceStatusEventType.gsmGatePhoneNumberStatusChanged, content);
  },
  publishGsmGateOnlineStatusChangedEvent: (content) => {
    publish(deviceStatusEventType.gsmGateOnlineStatusChanged, content);
  },
  publishOnlineStatusChangedEvent: (content) => {
    publish(deviceStatusEventType.onlineStatusChanged, content);
  },
};
