import { topicType } from "../enums/topicType";
import config from "../config";
import { deviceProfile } from "../enums/deviceProfile";

export const gsmDataHelper = {
    getGsmDataListByProfile: (device) => {
      const data = [];
      if (!device) return data;

      switch (device.profile) {
        case deviceProfile.imitator:
          for (let i = 1; i <= config.gsmModulesCountForImitatorProfile; i++) {
            data.push(getGsmTopicsByGsmModuleNumber(device, i));
          }

          break;
  
        case deviceProfile.wifi:
          data.push(getGsmTopicsByGsmModuleNumber(device, config.gsmModuleNumberForWifiProfile));
          break;
  
        default:
          return data;
      }
  
      return data;
    },   
  };

  const getGsmTopicsByGsmModuleNumber = (device, gsmModuleNumber) => {
    const gsmSignalLevelStatusTopicType = `gsm${gsmModuleNumber}SignalLevelStatus`;
    const gsmBalanceStatusTopicType = `gsm${gsmModuleNumber}BalanceStatus`;
    
    return {
      gsmModuleNumber: gsmModuleNumber,
      gsmSignalLevelStatusTopicType: topicType[gsmSignalLevelStatusTopicType],
      gsmBalanceStatusTopicType: topicType[gsmBalanceStatusTopicType],
      gsmSignalLevelStatus: device[gsmSignalLevelStatusTopicType],
      gsmSignalLevelStatusLastUpdated: device[`${gsmSignalLevelStatusTopicType}LastUpdated`],
      gsmBalanceStatus: device[gsmBalanceStatusTopicType],
      gsmBalanceLastUpdated: device[`${gsmBalanceStatusTopicType}LastUpdated`],
    };
  }