export const cursorHelper = {
  enforceCursorPosition: (inputRef, inputText) => {
    const input = inputRef.current;
    const cursorPosition = input.selectionStart;

    if (
      cursorPosition > 0 &&
      inputText[cursorPosition - 1] !== ";" &&
      cursorPosition < inputText.length
    ) {
      const prevSemicolonPosition = inputText.lastIndexOf(
        ";",
        cursorPosition - 1
      );
      if (prevSemicolonPosition === -1) {
        input.setSelectionRange(0, 0);
      } else {
        const newCursorPosition = prevSemicolonPosition + 1;
        input.setSelectionRange(newCursorPosition, newCursorPosition);
      }
    }
  },
};
