import { useState } from "react";
import { Link } from "react-router-dom";
import classes from "../css/navigationBar.module.css";
import buttonClasses from "../css/assets/buttons/button.module.css";
import { navigationBarDictionary } from "../../dictionary/ua/navigationBarDictionary";
import { links } from "../../enums/links";
import Button from "../assets/buttons/Button";
import NavigationLogoSelector from "../assets/icons/NavigationLogoSelector";
import { authenticationDictionary } from "../../dictionary/ua/authenticationDictionary";
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Logout from '@mui/icons-material/Logout';
import React from 'react';

const NavigationBar = ({ cookies }) => {
  const [active, setActive] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const navs = [
    {
      id: "deviceList",
      navTitle: navigationBarDictionary.deviceListPageTitle,
      link: links.deviceListLink,
    },
    {
      id: "devices",
      navTitle: navigationBarDictionary.devicesPageTitle,
      link: links.devicesLink,
    },
    {
      id: "mqttSettings",
      navTitle: navigationBarDictionary.mqttSettingsPageTitle,
      link: links.connectToMqttLink,
    },
    {
      id: "sheduling",
      navTitle: navigationBarDictionary.schedulingPageTitle,
      link: links.schedulingLink,
    }
  ];

  const isAuthenticated = () => {
    return cookies.get("token")?.token ? true : false;
  }

  const getUsername = () => {
    return cookies.get("token")?.username;
  };

  const setActivityStatus = (id) => (active === id ? "active" : "");

  return (
    <div className={classes.navigation}>
      <NavigationLogoSelector />
      {navs.map((item) => (
        <Link key={item.id} to={item.link}>
          <Button
            className={`${buttonClasses["nav-button"]} ${
              buttonClasses[setActivityStatus(item.id)]
            }`}
            onClick={() => setActive(item.id)}
          >
            {item.navTitle}
          </Button>
        </Link>
      ))}

      {isAuthenticated() && (
        <div>
          <React.Fragment>
            <Box>
              <Tooltip title={navigationBarDictionary.currentUserTitle}>
                <IconButton
                  onClick={handleClick}
                  size="small"
                  aria-controls={open ? "account-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                >
                  <AccountBoxIcon
                    className={classes["account-box-icon"]}
                  ></AccountBoxIcon>
                  {getUsername()}
                </IconButton>
              </Tooltip>
            </Box>
            <Menu
              id="account-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              onClick={handleClose}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <MenuItem
                onClick={handleClose}
                className={classes["account-menu"]}
              >
                <ListItemIcon>
                  <Logout />
                </ListItemIcon>
                <Link
                  className={classes["logout-link"]}
                  key="logout-link"
                  to={links.logOutLink}
                >
                  {authenticationDictionary.logOutButtonText}
                </Link>
              </MenuItem>
            </Menu>
          </React.Fragment>
        </div>
      )}
    </div>
  );
};

export default NavigationBar;
