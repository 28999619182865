import { useEffect } from "react";
import classes from "../../../css/deviceStatus.module.css";
import { useMqttConnectionContext } from "../../../context/AppContext";
import { Subscribe } from "../../../../mqtt/mqttService";
import { topicType } from "../../../../enums/topicType";
import { mqttConnectionStatus } from "../../../../enums/mqttConnectionStatus";
import config from "../../../../config";
import { getTopicName } from "../../../../helpers/getTopicName";
import { devicesListDictionary } from "../../../../dictionary/ua/devicesListDictionary";
import { Tooltip } from "@mui/material";
import RelayPicker from "../../../assets/relay/RelayPicker";

const RelayStatus = ({ device }) => {
  const { connectionStatus } = useMqttConnectionContext();
  const getTooltipTitle = (date) => {
    if(!isNaN(Date.parse(date))) {
      return `${devicesListDictionary.lastUpdatedStatusText} ${date.toLocaleString(config.localization.ua)}`;
    }
    
    return "";
  }

  useEffect(() => {
    if (connectionStatus === mqttConnectionStatus.isConnected) {
      setTimeout(() => {
        Subscribe(
          getTopicName(device.clientId, topicType.relayStatus),
          1
        );
      }, config.subscribeTimeoutMilliSeconds);
    }
  }, [device.clientId, connectionStatus]);

  return (
    <Tooltip
      title={getTooltipTitle(device.relayStatusLastUpdated)}
      arrow
      placement="bottom"
    >
      <div className={classes["device-status-wrapper"]}>
        <div>
          <RelayPicker relayMode={device.relayStatus} />
        </div>
      </div>
    </Tooltip>
  );
};

export default RelayStatus;
