import "./App.css";
import { React } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import MqttSettings from "./components/pages/mqtt-settings/MqttSettings";
import DeviceList from "./components/pages/device-list/DeviceList";
import Devices from "./components/pages/devices/Devices";
import NavigationBar from "./components/pages/NavigationBar";
import NotFoundPage from "./components/pages/NotFoundPage";
import { links } from "./enums/links";
import Login from "./components/pages/authentication/Login";
import { Cookies } from "react-cookie";
import Logout from "./components/pages/authentication/Logout";
import Scheduling from "./components/pages/scheduling/Scheduling";
import EditSchedule from "./components/pages/scheduling/EditSchedule";
import EditDevice from "./components/pages/devices/EditDevice";
import OnlineStatuses from "./components/pages/other/OnlineStatuses";

var cookies = new Cookies();

function App() {
  const isAuthenticated = () => {
    return cookies.get("token")?.token ? true : false;
  }

  return (
    <div className="App">
      <Router>
        <NavigationBar cookies={cookies} />
        <Routes>
          <Route
            path="/"
            element={
              isAuthenticated() ? (
                <MqttSettings cookies={cookies} />
              ) : (
                <Navigate to={links.loginPagePath} />
              )
            }
          />

          <Route
            path={links.connectToMqttPath}
            element={
              isAuthenticated() ? (
                <MqttSettings cookies={cookies} />
              ) : (
                <Navigate to={links.loginPagePath} />
              )
            }
          />

          <Route
            path={links.deviceListPath}
            element={
              isAuthenticated() ? (
                <Devices cookies={cookies} />
              ) : (
                <Navigate to={links.loginPagePath} />
              )
            }
          />

          <Route
            path={links.devicesPath}
            element={
              isAuthenticated() ? (
                <DeviceList cookies={cookies} />
              ) : (
                <Navigate to={links.loginPagePath} />
              )
            }
          />

          <Route
            path={links.schedulingPath}
            element={
              isAuthenticated() ? (
                <Scheduling cookies={cookies} />
              ) : (
                <Navigate to={links.loginPagePath} />
              )
            }
          />

          <Route
            path={links.loginPagePath}
            element={
              !isAuthenticated() ? (
                <Login />
              ) : (
                <Navigate to={links.devicesPath} />
              )
            }
          />

          <Route path={links.logOutPath} element={<Logout />} />

          <Route
            path={`${links.scheduleEditPath}/:id`}
            element={
              isAuthenticated() ? (
                <EditSchedule cookies={cookies} />
              ) : (
                <Navigate to={links.loginPagePath} />
              )
            }
          />

          <Route
            path={`${links.deviceEditPath}/:id`}
            element={
              isAuthenticated() ? (
                <EditDevice cookies={cookies} />
              ) : (
                <Navigate to={links.loginPagePath} />
              )
            }
          />

          <Route
            path={links.onlineStatusesPath}
            element={
              isAuthenticated() ? (
                <OnlineStatuses cookies={cookies} />
              ) : (
                <Navigate to={links.loginPagePath} />
              )
            }
          />

          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
