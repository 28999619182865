import { useEffect, useState } from "react";
import { deviceApi } from "../../../api/deviceApi";
import { useNavigate } from "react-router-dom";
import { links } from "../../../enums/links";
import config from "../../../config";
import { refreshPage } from "../../../helpers/windowOperations";
import classes from "../../css/pages/other/onlineStatuses.module.css";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

const OnlineStatuses = ({ cookies }) => {
  const navigate = useNavigate();
  const [onlineStatusesList, setOnlineStatusesList] = useState([]);
  const [sortConfig, setSortConfig] = useState({
    key: "lastOnlineUtc",
    direction: "ascending",
  });
  
  dayjs.extend(utc);
  dayjs.extend(timezone);

  const handleSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const sortArray = (array, { key, direction }) => {
    const sortedArray = [...array].sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === "ascending" ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === "ascending" ? 1 : -1;
      }
      return 0;
    });
    return sortedArray;
  };

  const sortedOnlineStatusesList = sortArray(onlineStatusesList, sortConfig);

  const isAuthenticated = () => {
    return cookies.get("token")?.token ? true : false;
  };

  const getUserId = () => {
    return cookies.get("token")?.userId;
  };

  function convertUtcToLocal(utcDateTime) {
    return dayjs.utc(utcDateTime).local().format("YYYY-MM-DD HH:mm:ss");
  }

  const fetchOnlineStatusesList = () => {
    deviceApi
      .getOnlineStatuses(getUserId())
      .then((response) => {
        setOnlineStatusesList(response);
      })
      .catch(function (error) {
        //log error
      });
  };

  useEffect(() => {
    if (isAuthenticated()) {
      fetchOnlineStatusesList();
    }

    const interval = setInterval(() => {
      if (!isAuthenticated()) {
        navigate(links.loginPagePath);
        refreshPage();
      }
    }, config.checkCookieExpirationMilliseconds);

    return () => {
      clearInterval(interval);
    };
  }, []);

  if (onlineStatusesList.length === 0) return <h2>Немає даних</h2>;

  return (
    isAuthenticated() && (
      <>
        <table>
          <thead>
            <tr>
              <th className={classes["table-header"]} onClick={() => handleSort("clientId")}>
                ID
                {sortConfig.key === "clientId"
                  ? sortConfig.direction === "ascending"
                    ? "🔼"
                    : "🔽"
                  : ""}
              </th>
              <th className={classes["table-header"]} onClick={() => handleSort("deviceName")}>
                Ім'я пристрою
                {sortConfig.key === "deviceName"
                  ? sortConfig.direction === "ascending"
                    ? "🔼"
                    : "🔽"
                  : ""}
              </th>
              <th className={classes["table-header"]} onClick={() => handleSort("lastOnlineUtc")}>
                Востаннє онлайн
                {sortConfig.key === "lastOnlineUtc"
                  ? sortConfig.direction === "ascending"
                    ? "🔼"
                    : "🔽"
                  : ""}
              </th>
              <th className={classes["table-header"]} onClick={() => handleSort("ownerUsername")}>
                Власник
                {sortConfig.key === "ownerUsername"
                  ? sortConfig.direction === "ascending"
                    ? "🔼"
                    : "🔽"
                  : ""}
              </th>
            </tr>
          </thead>
          <tbody>
            {sortedOnlineStatusesList.map((status) => (
              <tr key={status.id}>
                <td>{status.clientId}</td>
                <td>{status.deviceName}</td>
                <td>
                  {status.lastOnlineUtc
                    ? convertUtcToLocal(status.lastOnlineUtc)
                    : "N/A"}
                </td>
                <td>{status.ownerUsername}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </>
    )
  );
};

export default OnlineStatuses;
