import checkIconClasses from "../../css/assets/icons/checkIcon.module.css";
import { mqttConnectionStatus } from "../../../enums/mqttConnectionStatus";
import { useMqttConnectionContext } from "../../context/AppContext";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import CloseIcon from "@mui/icons-material/Close";
import connectionStatusClasses from "../../css/pages/mqtt-settings/connectionStatus.module.css";
import { mqttConnectDictionary } from "../../../dictionary/ua/mqttConnectDictionary";
import LoadingButton from "@mui/lab/LoadingButton";
import ControlPointIcon from "@mui/icons-material/ControlPoint";

const ConnectionStatus = (props) => {
  const { connectionStatus } = useMqttConnectionContext();
  const isConnected = () => {
    return connectionStatus === mqttConnectionStatus.isConnected;
  };

  const isConnecting = () => {
    return connectionStatus === mqttConnectionStatus.isConnecting;
  };

  const isNotConnected = () => {
    return connectionStatus === mqttConnectionStatus.isNotConnected;
  };

  return (
    <>
      <LoadingButton sx={{backgroundColor: "#385494"}}
        type="submit"
        disabled={props.isConnectButtonDisabled}
        startIcon={<ControlPointIcon />}
        loading={isConnecting()}
        loadingPosition="start"
        variant="contained"
      >
        <span>{mqttConnectDictionary.connectToMqttButtonText}</span>
      </LoadingButton>
      {isConnected() && (
        <>
          <DoneAllIcon className={checkIconClasses["connection-status-icon"]} />
          <div className={connectionStatusClasses["connection-status-text"]}>
            {mqttConnectDictionary.connectedToMqttText}
          </div>
        </>
      )}
      {isNotConnected() && (
        <>
          <CloseIcon className={checkIconClasses["connection-status-icon"]} />
          <div className={connectionStatusClasses["connection-status-text"]}>
            {mqttConnectDictionary.notConnectedToMqttText}
          </div>
        </>
      )}
    </>
  );
};

export default ConnectionStatus;
