import { useEffect } from "react";
import { deviceStatusEventType } from "../../../../enums/eventType";
import { subscribe, unsubscribe } from "../../../../events/event";
import { onlineStatus } from "../../../../enums/onlineStatus";

const GsmGateOnlineStatusUpdater = ({ setDeviceList }) => {
  const updateDeviceStatus = (data) => {
    const parsed = JSON.parse(data.detail);
    const newStatus = parsed.data;

    setDeviceList((prevState) => {
      const foundDevice = Object.keys(prevState).reduce((found, profile) => {
        const device = prevState[profile].array.find(
          (d) => d.clientId === parsed.clientId
        );
        return device ? { profile, device } : found;
      }, null);

      if (foundDevice) {
        const { profile } = foundDevice;
        return {
          ...prevState,
          [profile]: {
            array: prevState[profile].array.map((d) =>
              d.clientId === parsed.clientId
                ? {
                    ...d,
                    gsmGateOnlineStatus: newStatus,
                    online: newStatus === onlineStatus.online ? true : false
                  }
                : d
            ),
          },
        };
      }
    });
  };

  useEffect(() => {
    subscribe(
      deviceStatusEventType.gsmGateOnlineStatusChanged,
      updateDeviceStatus
    );
    
    return () => {
      unsubscribe(
        deviceStatusEventType.gsmGateOnlineStatusChanged,
        updateDeviceStatus
      );
    };
  }, []);

  return <></>;
};

export default GsmGateOnlineStatusUpdater;
