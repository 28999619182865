import { useEffect } from "react";
import { deviceStatusEventType } from "../../../../enums/eventType";
import { subscribe, unsubscribe } from "../../../../events/event";

const RadioAudioModeStatusUpdater = ({ setDeviceList }) => {
  const updateDeviceStatus = (data) => {
    const parsed = JSON.parse(data.detail);
    const newStatus = parsed.data;
    const lastUpdated = new Date(parsed.dateTime);

    setDeviceList((prevState) => {
      const foundDevice = Object.keys(prevState).reduce((found, profile) => {
        const device = prevState[profile].array.find(
          (d) => d.clientId === parsed.clientId
        );
        return device ? { profile, device } : found;
      }, null);

      if (foundDevice) {
        const { profile } = foundDevice;
        return {
          ...prevState,
          [profile]: {
            array: prevState[profile].array.map((d) =>
              d.clientId === parsed.clientId
                ? {
                    ...d,
                    radioAudioModeStatus: newStatus,
                    radioAudioModeStatusLastUpdated: lastUpdated,
                    deviceStateUpdatedDateTimeUtc: lastUpdated,
                  }
                : d
            ),
          },
        };
      }
    });
  };
  
  useEffect(() => {
    subscribe(
      deviceStatusEventType.radioAudioModeStatusChanged,
      updateDeviceStatus
    );
    
    return () => {
      unsubscribe(
        deviceStatusEventType.radioAudioModeStatusChanged,
        updateDeviceStatus
      );
    };
  }, []);

  return <></>;
};

export default RadioAudioModeStatusUpdater;
