import axios from "axios";

const headers = {
  "Content-Type": "application/json",
  "Api-Key": process.env?.REACT_APP_API_KEY
}

export const axiosClient = axios.create({  
  baseURL: process.env?.REACT_APP_API_BASE_URL,
  headers: headers
});

const errorHandler = (error) => {
  const statusCode = error.response?.status;

  if (statusCode && statusCode !== 401) {
    console.error(error);
  }
  
  return Promise.reject(error);
};

axiosClient.interceptors.response.use(undefined, (error) => {
  return errorHandler(error);
});
