import { axiosClient } from "../axios/axiosConfig";

export const authApi = {
  login: async function (loginData) {
    const response = await axiosClient.request({
      url: `/auth/login`,
      method: "POST",
      data: loginData,
    });

    return response.data;
  },
};
