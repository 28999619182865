import { useEffect } from "react";
import classes from "../../../css/statuses/gsmStatus.module.css";
import { useMqttConnectionContext } from "../../../context/AppContext";
import { Subscribe } from "../../../../mqtt/mqttService";
import { topicType } from "../../../../enums/topicType";
import { mqttConnectionStatus } from "../../../../enums/mqttConnectionStatus";
import config from "../../../../config";
import { getTopicName } from "../../../../helpers/getTopicName";
import GsmSignalLevelPicker from "../../../assets/icons/gsmSignalLevel/GsmSignalLevelPicker";
import GsmPhone from "../../../assets/icons/gsmPhone/GsmPhone";
import { deviceStatusType } from "../../../../enums/deviceStatus";
import { devicesListDictionary } from "../../../../dictionary/ua/devicesListDictionary";

const GsmGateGsmStatus = ({ device }) => {
  const { connectionStatus } = useMqttConnectionContext();
  const getGsmBalanceString = () =>
    device.gmsGateGsmBalanceStatus !== deviceStatusType.notApplicable &&
    device.gmsGateGsmBalanceStatus
      ? `${device.gmsGateGsmBalanceStatus} ${devicesListDictionary.uaCurrencyText}`
      : device.gmsGateGsmBalanceStatus;

  useEffect(() => {
    if (connectionStatus === mqttConnectionStatus.isConnected) {
      setTimeout(() => {
        Subscribe(
          getTopicName(device.clientId, topicType.gsmGateGsmBalanceStatus),
          1
        );

        Subscribe(
          getTopicName(device.clientId, topicType.gsmGateGsmSignalLevelStatus),
          1
        );

        Subscribe(
          getTopicName(device.clientId, topicType.gsmGatePhoneNumberStatus),
          1
        );

        Subscribe(
          getTopicName(device.clientId, topicType.gsmGateOnlineStatus),
          1
        );
      }, config.subscribeTimeoutMilliSeconds);
    }
  }, [device.clientId, connectionStatus, device.gsmGateGsmBalanceStatus]);

  return (
    <div className={classes["device-status-wrapper"]}>
      <div className={classes["gsm-status-wrapper"]}>
        <div>
          <GsmSignalLevelPicker
            gsmSignalLevel={device.gsmGateGsmSignalLevelStatus}
          />
        </div>
        <div>
          <GsmPhone gsmBalance={getGsmBalanceString()} phoneNumber={device.gsmGatePhoneNumberStatus} />
        </div>
      </div>
    </div>
  );
};

export default GsmGateGsmStatus;
