import classes from "../../css/pages/devices/deviceHeader.module.css";
import Button from "../../assets/buttons/Button";
import SortingIcon from "../../assets/icons/SortingIcon";
import buttonClasses from "../../css/assets/buttons/button.module.css";
import { useState } from "react";
import { devicesDictionary } from "../../../dictionary/ua/devicesDictionary";
import { deviceApi } from "../../../api/deviceApi";
import DeleteDevicesDialog from "../../assets/dialogs/DeleteDevicesDialog";

const DeviceHeader = ({ deviceList, setDeviceList, isAdmin }) => {
  const [open, setOpen] = useState(false);
  const [devicesIdsToDelete, setDevicesIdsToDelete] = useState([]);
  const [clientIdsToDelete, setClientIdsToDelete] = useState([]);
  const [active, setActive] = useState(null);
  const [pressed, setPressed] = useState(null);
  const setActivityStatus = (id) => {
    return active === id ? "active" : "";
  };
  const setPressedStatus = (id) => {
    return pressed === id ? "pressed" : "";
  };

  const handleClick = (event) => {
    // TODO: implement sorting
    setActive(event.currentTarget.id);
  };

  const handleMouseDown = (event) => {
    setPressed(event.currentTarget.id);
    document.addEventListener("mouseup", () => handleMouseUp(), { once: true });
  };

  const handleMouseUp = () => {
    setPressed(null);
  };

  const handleOpenDeleteDevicesDialog = () => {
    const devicesIdsToDelete = deviceList
      .filter((dev) => dev.toDelete === true)
      .map((dev) => dev.id);

    const clientIds = deviceList
      .filter((dev) => dev.toDelete === true)
      .map((dev) => dev.clientId);

    if (devicesIdsToDelete.length > 0) {
      setClientIdsToDelete(clientIds);
      setDevicesIdsToDelete(devicesIdsToDelete);
      setOpen(true);
    }
  };

  const handleCloseDeleteDevicesDialog = (selectedToDelete) => {
    setOpen(false);
    if (selectedToDelete === true) {
      devicesIdsToDelete.forEach((deviceId) => {
        deviceApi.delete(deviceId).then((response) => {
          if (response === true) {
            setDeviceList((prevState) => {
              return prevState.filter((dev) => dev.id !== deviceId);
            });
          }
        });
      });
    }
  };

  return (
    <>
      <div className={classes["header"]}>
        <div className={classes["clientId-header-column"]}>
          <Button
            id="clientIdButton"
            className={`${buttonClasses["column-header-button"]} ${
              buttonClasses["clientId-header-button"]
            } ${buttonClasses[setActivityStatus("clientIdButton")]} ${
              buttonClasses[setPressedStatus("clientIdButton")]
            }`}
            icon={<SortingIcon />}
            startIcon="end"
            onClick={handleClick}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
          >
            {devicesDictionary.clientIdHeaderText}
          </Button>
        </div>
        <div className={classes["clientName-header-column"]}>
          <Button
            id="clientNameButton"
            className={`${buttonClasses["column-header-button"]} ${
              buttonClasses["clientName-header-button"]
            } ${buttonClasses[setActivityStatus("clientNameButton")]} ${
              buttonClasses[setPressedStatus("clientNameButton")]
            }`}
            icon={<SortingIcon />}
            startIcon="end"
            onClick={handleClick}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
          >
            {devicesDictionary.clientNameHeaderText}
          </Button>
        </div>
        <div className={classes["profile-header-column"]}>
          <Button
            id="profileButton"
            className={`${buttonClasses["column-header-button"]} ${
              buttonClasses["profile-header-button"]
            } ${buttonClasses[setActivityStatus("profileButton")]} ${
              buttonClasses[setPressedStatus("profileButton")]
            }`}
            icon={<SortingIcon />}
            startIcon="end"
            onClick={handleClick}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
          >
            {devicesDictionary.profileHeaderText}
          </Button>
        </div>
        <div className={classes["delete-device-header-column"]}>
          <Button
            id="deleteDeviceButton"
            className={`${buttonClasses["column-header-button"]} ${
              buttonClasses["delete-device-header-button"]
            } ${buttonClasses[setPressedStatus("deleteDeviceButton")]}`}
            startIcon="end"
            onClick={handleOpenDeleteDevicesDialog}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
          >
            {devicesDictionary.deleteDeviceHeaderText}
          </Button>
          <DeleteDevicesDialog
            open={open}
            onClose={handleCloseDeleteDevicesDialog}
            clientIds={clientIdsToDelete}
          />
        </div>
        {isAdmin && (
          <div className={classes["ownerUsername-header-column"]}>
            <Button
              id="ownerUsername"
              className={`${buttonClasses["column-header-button"]} ${
                buttonClasses["ownerUsername-header-button"]
              } ${buttonClasses[setActivityStatus("ownerUsername")]} ${
                buttonClasses[setPressedStatus("ownerUsername")]
              }`}
              icon={<SortingIcon />}
              startIcon="end"
              onClick={handleClick}
              onMouseDown={handleMouseDown}
              onMouseUp={handleMouseUp}
            >
              {devicesDictionary.ownerUsernameHeaderText}
            </Button>
          </div>
        )}
      </div>
    </>
  );
};

export default DeviceHeader;
