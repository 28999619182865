import { useEffect } from "react";
import { topicType } from "../../../../enums/topicType";
import { getTopicName } from "../../../../helpers/getTopicName";
import { Subscribe } from "../../../../mqtt/mqttService";
import { useMqttConnectionContext } from "../../../context/AppContext";
import { mqttConnectionStatus } from "../../../../enums/mqttConnectionStatus";
import config from "../../../../config";

const LastWillStatus = ({ clientId }) => {
  const { connectionStatus } = useMqttConnectionContext();
  useEffect(() => {
    if (connectionStatus === mqttConnectionStatus.isConnected) {
      setTimeout(() => {
        Subscribe(getTopicName(clientId, topicType.lastWillStatus), 1);
      }, config.subscribeTimeoutMilliSeconds);
    }
  }, [clientId, connectionStatus]);

  return <></>;
};

export default LastWillStatus;
