import React, { useState } from "react";
import classes from "../../css/pages/devices/addDevice.module.css";
import { deviceApi } from "../../../api/deviceApi";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { addNewDeviceSchema } from "../../../validation/validationSchemas";
import { addDeviceDictionary } from "../../../dictionary/ua/addDeviceDictionary";
import PlusIcon from "../../assets/icons/PlusIcon";
import { FormControl, FormHelperText, MenuItem, OutlinedInput, Select } from "@mui/material";
import Button from "../../assets/buttons/Button";
import buttonClasses from "../../css/assets/buttons/button.module.css";
import { addDeviceErrorMapper } from "../../../enums/errorMappers/addDeviceErrorMapper";
import { deviceProfile } from "../../../enums/deviceProfile";
import ImitatorProfile from "../../assets/dropdowns/profiles/ImitatorProfile";
import WifiProfile from "../../assets/dropdowns/profiles/WifiProfile";
import GsmGateProfile from "../../assets/dropdowns/profiles/GsmGateProfile";

function AddDevice({setDeviceList, userId}) {
  const [apiErrors, setApiErrors] = useState([]);
  const [pressed, setPressed] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [profile, setProfile] = useState(deviceProfile.imitator);
  const {
    register,
    handleSubmit,
    getFieldState,
    reset,
    formState: { errors, isDirty },
  } = useForm({
    resolver: yupResolver(addNewDeviceSchema),
    mode: "onChange"
  });

  const onSubmit = (data) => {
    let payload = {
      clientId: data.clientId,
      name: data.name,
      profile: data.profile,
      userId: userId
    };

    setDisabled(true);

    deviceApi.create(payload).then((response) => {
      if (response.success === true) {
        reset({
          clientId: "",
          name: ""
        });

        setDeviceList((prevState) => {
          payload.id = response.result.id;
          return [...prevState, payload];
        });
      }
      else {     
        var errorsCodesAndErrorTexts = response.errors.map((e) => {
          return {
            errorCode: e.errorCode,
            errorText: addDeviceErrorMapper[e.errorCode],
          };
        });

        setApiErrors(errorsCodesAndErrorTexts);
      }
    });
  };

  const setPressedClass = () => {
    return pressed === true ? "pressed" : "";
  };

  const handleMouseDown = () => {
    setPressed(true);
    document.addEventListener("mouseup", () => handleMouseUp(), { once: true });
  };

  const handleMouseUp = () => {
    setPressed(false);
  };

  const isDirtyAndValid = () => {
    if (
      getFieldState("clientId").isDirty &&
      !getFieldState("clientId").invalid &&
      getFieldState("name").isDirty &&
      !getFieldState("name").invalid
    ) {
      return true;
    }

    return false;
  };

  const handleChange = () => {
    if (isDirtyAndValid()) {
      setDisabled(false);
    }

    setApiErrors([]);
  };

  const handleProfileChange = (event) => {
    setDisabled(false);
    setProfile(event.target.value);
  };

  return (
    <div className={classes["add-device-wrapper"]}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <FormControl
            className={classes["form-control"]}
            error={getFieldState("clientId").invalid}
            onChange={handleChange}
          >
            <span className={classes["label-container"]}>
              <label htmlFor="clientId">
                {addDeviceDictionary.clientIdText}
              </label>
            </span>
            <OutlinedInput
              id="clientId"
              type="text"
              name="clientId"
              size="small"
              {...register("clientId")}
            />
            <FormHelperText>{errors?.clientId?.message}</FormHelperText>
            {apiErrors.map((e) => (
              <FormHelperText key={e.errorCode}>{e.errorText}</FormHelperText>
            ))}
          </FormControl>
        </div>

        <div>
          <FormControl
            className={classes["form-control"]}
            error={getFieldState("name").invalid}
            onChange={handleChange}
          >
            <span className={classes["label-container"]}>
              <label htmlFor="name">{addDeviceDictionary.clientNameText}</label>
            </span>
            <OutlinedInput
              id="name"
              type="text"
              name="name"
              size="small"
              {...register("name")}
            />
            <FormHelperText>{errors?.name?.message}</FormHelperText>
          </FormControl>
        </div>

        <div>
          <FormControl
            className={classes["form-control"]}
            error={getFieldState("profile").invalid}
          >
            <span className={classes["label-container"]}>
              <label htmlFor="profile">{addDeviceDictionary.profileText}</label>
            </span>
            <Select
              id="profile"
              name="profile"
              className={classes["profile-select"]}
              value={profile}
              size="small"
              {...register("profile")}
              onChange={handleProfileChange}
            >
              <MenuItem value={deviceProfile.imitator}>
                <ImitatorProfile>
                  {addDeviceDictionary.imitatorProfileText}
                </ImitatorProfile>
              </MenuItem>
              <MenuItem value={deviceProfile.wifi}>
                <WifiProfile>{addDeviceDictionary.wifiProfileText}</WifiProfile>
              </MenuItem>
              <MenuItem value={deviceProfile.gsmGate}>
                <GsmGateProfile>
                  {addDeviceDictionary.gsmGateProfileText}
                </GsmGateProfile>
              </MenuItem>
            </Select>
            <FormHelperText>{errors?.profile?.message}</FormHelperText>
          </FormControl>
        </div>

        <div>
          <Button
            className={`${buttonClasses["add-device-button"]} ${
              buttonClasses[setPressedClass()]
            }`}
            disabled={disabled}
            icon={<PlusIcon />}
            startIcon="start"
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
          >
            {addDeviceDictionary.addDeviceButtonText}
          </Button>
        </div>
      </form>
    </div>
  );
}

export default AddDevice;
