import React, { useRef } from "react";
import WaitForNSeconds from "./commands/WaitForNSeconds";
import WaitFromN_to_M_seconds from "./commands/WaitFromN_to_M_seconds";
import DialFromNGsmToPhoneNumber from "./commands/DialFromNGsmToPhoneNumber";
import HangUpActiveCallForGsmModule from "./commands/HangUpActiveCallForGsmModule";
import SetGsmMode from "./commands/SetGsmMode";
import SetRadioMode from "./commands/SetRadioMode";
import RepeatNTimes from "./commands/RepeatNTimes";
import SetRadioChannelForBothTransceivers from "./commands/SetRadioChannelForBothTransceivers";
import { OutlinedInput, Paper, Typography } from "@mui/material";
import { schedulingDictionary } from "../../../dictionary/ua/scheduling/schedulingDictionary";
import classes from "../../css/pages/scheduling/scheduleBuilder.module.css";
import { repeatOption } from "../../../enums/scheduling/repeatOption";
import { onceOption } from "../../../enums/scheduling/onceOption";
import { cursorHelper } from "../../../helpers/cursorHelper";
const ScheduleBuilder = ({ scheduleString, setScheduleString }) => {
  const inputRef = useRef(null);

  const insertAtCursor = (text, commandButtonLabel = "") => {
    switch (commandButtonLabel) {
      case repeatOption.repeatNTimes: {
        const repeatRegex = /R=\d+;/g;
        if (repeatRegex.test(scheduleString)) {
          setScheduleString(scheduleString.replace(repeatRegex, ""));
        } else {
          const onceRegex = /^ONCE;$/g;
          if (onceRegex.test(scheduleString)) {
            setScheduleString(scheduleString.replace(onceRegex, ""));
          }

          setScheduleString((prev) => text + prev);
        }

        break;
      }

      case onceOption.ONCE: {
        const onceRegex = /^ONCE;$/g;
        if (onceRegex.test(scheduleString)) {
          setScheduleString(scheduleString.replace(onceRegex, ""));
        } else {
          const repeatRegex = /R=\d+;/g;
          if (repeatRegex.test(scheduleString)) {
            setScheduleString(scheduleString.replace(repeatRegex, ""));
          }

          setScheduleString((prev) => text + prev);
        }

        break;
      }

      default: {
        const input = inputRef.current;
        const start = input.selectionStart;
        const end = input.selectionEnd;
        const newValue =
          scheduleString.slice(0, start) + text + scheduleString.slice(end);
        setScheduleString(newValue);
        input.setSelectionRange(start + text.length, start + text.length);
      }
    }
  };

  const handleKeyDown = (event) => {
    const input = inputRef.current;
    const cursorPosition = input.selectionStart;
  
    if (event.key === 'Backspace' && cursorPosition > 0) {
      event.preventDefault();
      const prevSemicolonPosition = scheduleString.lastIndexOf(';', cursorPosition - 2);
      const commandStart = prevSemicolonPosition === -1 ? 0 : prevSemicolonPosition + 1;
      const commandEnd = cursorPosition - 1;
      setScheduleString(scheduleString.slice(0, commandStart) + scheduleString.slice(commandEnd + 1));
      input.setSelectionRange(commandStart, commandStart);
    }
  
    if (event.key === 'Delete' && cursorPosition < scheduleString.length) {
      event.preventDefault();
      const nextSemicolonPosition = scheduleString.indexOf(';', cursorPosition);
      const commandEnd = nextSemicolonPosition === -1 ? scheduleString.length : nextSemicolonPosition + 1;
      setScheduleString(scheduleString.slice(0, cursorPosition) + scheduleString.slice(commandEnd));
      input.setSelectionRange(cursorPosition, cursorPosition);
    }
  
    if (event.key !== 'Backspace' && event.key !== 'Delete') {
      event.preventDefault();
    }
  };

  return (
    <div className={classes["schedule-builder-container"]}>
      <div className={classes["schedule-commands-container"]}>
        <Typography variant="h6">Очікування</Typography>
        <Paper
          className={classes["commands-group"]}
          elevation={3}
          style={{ padding: 16, marginBottom: 16 }}
        >
          <WaitForNSeconds insertAtCursor={insertAtCursor} />
          <WaitFromN_to_M_seconds insertAtCursor={insertAtCursor} />
        </Paper>

        <Typography variant="h6">GSM</Typography>
        <Paper
          className={classes["commands-group"]}
          elevation={3}
          style={{ padding: 16, marginBottom: 16 }}
        >
          <DialFromNGsmToPhoneNumber insertAtCursor={insertAtCursor} />
          <HangUpActiveCallForGsmModule insertAtCursor={insertAtCursor} />
          <SetGsmMode insertAtCursor={insertAtCursor} />
        </Paper>

        <Typography variant="h6">Радіо</Typography>
        <Paper
          className={classes["commands-group"]}
          elevation={3}
          style={{ padding: 16, marginBottom: 16 }}
        >
          <SetRadioMode insertAtCursor={insertAtCursor} />
          <SetRadioChannelForBothTransceivers insertAtCursor={insertAtCursor} />
        </Paper>

        <Typography variant="h6">Повтори</Typography>
        <Paper
          className={classes["commands-group"]}
          elevation={3}
          style={{ padding: 16, marginBottom: 16 }}
        >
          <RepeatNTimes insertAtCursor={insertAtCursor} />
        </Paper>
      </div>

      <div className={classes["schedule-string-container"]}>
        <span>{schedulingDictionary.scheduleLabelText}</span>
        <OutlinedInput
          className={classes["schedule-string-control"]}
          id="schedule"
          type="text"
          name="schedule"
          size="small"
          value={scheduleString}
          inputRef={inputRef}
          onChange={(e) => setScheduleString(e.target.value)}
          onClick={() => cursorHelper.enforceCursorPosition(inputRef, scheduleString)}
          onKeyUp={() => cursorHelper.enforceCursorPosition(inputRef, scheduleString)}
          onKeyDown={handleKeyDown}
        />
      </div>
    </div>
  );
};

export default ScheduleBuilder;
