import { useEffect } from "react";
import classes from "../../../css/statuses/gsmStatus.module.css";
import { useMqttConnectionContext } from "../../../context/AppContext";
import { Subscribe } from "../../../../mqtt/mqttService";
import { mqttConnectionStatus } from "../../../../enums/mqttConnectionStatus";
import config from "../../../../config";
import { getTopicName } from "../../../../helpers/getTopicName";
import GsmSignalLevelPicker from "../../../assets/icons/gsmSignalLevel/GsmSignalLevelPicker";
import { devicesListDictionary } from "../../../../dictionary/ua/devicesListDictionary";
import { Tooltip } from "@mui/material";
import GsmBalance from "../../../assets/icons/gsmPhone/GsmPhone";

const GsmStatus = ({ clientId, gsmDataList }) => {
  const { connectionStatus } = useMqttConnectionContext();
  const getTooltipTitle = (date) => {
    if (!isNaN(Date.parse(date))) {
      return `${
        devicesListDictionary.lastUpdatedStatusText
      } ${date.toLocaleString(config.localization.ua)}`;
    }

    return "";
  };

  useEffect(() => {
    if (connectionStatus === mqttConnectionStatus.isConnected) {
      setTimeout(() => {
        gsmDataList.map((gsmData) => {
          Subscribe(
            getTopicName(clientId, gsmData.gsmSignalLevelStatusTopicType),
            1
          );

          Subscribe(
            getTopicName(clientId, gsmData.gsmBalanceStatusTopicType),
            1
          );
        });
      }, config.subscribeTimeoutMilliSeconds);
    }
  }, [clientId, connectionStatus]);

  return (
    <div className={classes["device-status-wrapper"]}>
      {gsmDataList.map((gsmData) => (
        <div className={classes["gsm-status-wrapper"]} key={gsmData.gsmModuleNumber}>
          <Tooltip
            title={getTooltipTitle(gsmData.gsmSignalLevelStatusLastUpdated)}
            arrow
            placement="top"
          >
            <div>
              <GsmSignalLevelPicker
                gsmSignalLevel={gsmData.gsmSignalLevelStatus}
              />
            </div>
          </Tooltip>

          <div>
            <GsmBalance
              gsmBalance={gsmData.gsmBalanceStatus}
              gsmBalanceLastUpdated={gsmData.gsmBalanceStatusLastUpdated}
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default GsmStatus;
