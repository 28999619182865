export const topicType = {
    radioSilenceStatus: "radioSilenceAck",
    gsmAudioModeStatus: "gsmAudioModeAck",
    radioAudioModeStatus: "radioAudioModeAck",
    scheduleStatus: "scheduleAck",
    batteryVoltageLevelStatus: "batteryVoltageLevel",
    gsm1SignalLevelStatus: "gsm1SignalLevel",
    gsm2SignalLevelStatus: "gsm2SignalLevel",
    gsm3SignalLevelStatus: "gsm3SignalLevel",
    radioSilenceCommand: "radioSilenceCmd",
    gsmAudioModeCommand: "gsmAudioModeCmd",
    radioAudioModeCommand: "radioAudioModeCmd",
    scheduleCommand: "scheduleCmd",
    lastWillStatus: "Will",
    onlineStatus: "Online",
    gsm1BalanceStatus: "gsm1Balance",
    gsm2BalanceStatus: "gsm2Balance",
    gsm3BalanceStatus: "gsm3Balance",
    relayCommand: "relayCmd",
    relayStatus: "relayAck",
    fdoCommand: "sys/ctrl/fdo",
    gsmGateDIStatus: "sys/mon/gen/di",
    gsmGateOnlineStatus: "status",
    gsmGateGsmSignalLevelStatus: "sys/mon/gsm/s_lvl",
    gsmGateGsmBalanceStatus: "sys/mon/gsm/grn",
    gsmGatePhoneNumberStatus: "sys/mon/gen/gate_phone"
  };
  