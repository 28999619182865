export const deviceStatusEventType = {
  radioSilenceStatusChanged: "radioSilenceStatusChanged",
  gsmAudioModeStatusChanged: "gsmAudioModeStatusChanged",
  radioAudioModeStatusChanged: "radioAudioModeStatusChanged",
  scheduleStatusChanged: "scheduleStatusChanged",
  batteryVoltageLevelStatusChanged: "batteryVoltageLevelStatusChanged",
  gsm1SignalLevelStatusChanged: "gsm1SignalLevelStatusChanged",
  gsm2SignalLevelStatusChanged: "gsm2SignalLevelStatusChanged",
  gsm3SignalLevelStatusChanged: "gsm3SignalLevelStatusChanged",
  lastWillStatusChanged: "lastWillStatusChanged",
  gsm1BalanceStatusChanged: "gsm1BalanceStatusChanged",
  gsm2BalanceStatusChanged: "gsm2BalanceStatusChanged",
  gsm3BalanceStatusChanged: "gsm3BalanceStatusChanged",
  relayStatusChanged: "relayStatusChanged",
  gsmGateGsmSignalLevelStatusChanged: "gsmGateGsmSignalLevelStatusChanged",
  gsmGateGsmBalanceStatusChanged: "gsmGateGsmBalanceStatusChanged",
  gsmGateDIStatusChanged: "gsmGateDIStatusChanged",
  gsmGatePhoneNumberStatusChanged: "gsmGatePhoneNumberStatusChanged",
  gsmGateOnlineStatusChanged: "gsmGateOnlineStatusChanged",
  onlineStatusChanged: "onlineStatusChanged"
};

export const mqttConnectionLostEventType = {
  mqttConnectionLost: "mqttConnectionLost"
};
