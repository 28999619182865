import { axiosClient } from "../axios/axiosConfig";

export const deviceApi = {
  getAll: async function () {
    const response = await axiosClient.request({
      url: "/devices",
      method: "GET",
    });

    return response.data;
  },
  getAllByUserId: async function (userId) {
    const response = await axiosClient.request({
      url: `/devices/userId?userId=${userId}`,
      method: "GET",
    });

    return response.data;
  },
  getAllWithStateByUserId: async function (userId) {
    const response = await axiosClient.request({
      url: `/devices/state/userId?userId=${userId}`,
      method: "GET",
    });

    return response.data;
  },
  getById: async function (id) {
    const response = await axiosClient.request({
      url: `/devices/${id}`,
      method: "GET",
    });

    return response.data;
  },
  create: async function (device) {
    const response = await axiosClient.request({
      url: `/devices`,
      method: "POST",
      data: device,
    });

    return response.data;
  },
  createOrUpdateDeviceState: async function (deviceState) {
    const response = await axiosClient.request({
      url: `/devices/state`,
      method: "PUT",
      data: deviceState
    });

    return response.data;
  },
  update: async function (device) {
    const response = await axiosClient.request({
      url: `/devices`,
      method: "PUT",
      data: device,
    });

    return response.data;
  },
  delete: async function (id) {
    const response = await axiosClient.request({
      url: `/devices/${id}`,
      method: "DELETE",
    });

    return response.data;
  },
  getOnlineStatuses: async function (userId) {
    const response = await axiosClient.request({
      url: `/devices/onlineStatuses/userId?userId=${userId}`,
      method: "GET",
    });

    return response.data;
  },
};
