import { useEffect } from "react";
import classes from "../../../css/deviceStatus.module.css";
import { useMqttConnectionContext } from "../../../context/AppContext";
import { Subscribe } from "../../../../mqtt/mqttService";
import { topicType } from "../../../../enums/topicType";
import { mqttConnectionStatus } from "../../../../enums/mqttConnectionStatus";
import config from "../../../../config";
import { getTopicName } from "../../../../helpers/getTopicName";
import DIStatusPicker from "../../../assets/dropdowns/diStatus/DIStatusPicker";

const GsmGateDIStatus = ({ device }) => {
  const { connectionStatus } = useMqttConnectionContext();

  useEffect(() => {
    if (connectionStatus === mqttConnectionStatus.isConnected) {
      setTimeout(() => {
        Subscribe(
          getTopicName(device.clientId, topicType.gsmGateDIStatus),
          1
        );
      }, config.subscribeTimeoutMilliSeconds);
    }
  }, [device.clientId, connectionStatus]);

  return (
    <div className={classes["device-status-wrapper"]}>
      <div>
        <DIStatusPicker diStatus={device.gsmGateDIStatus} />
      </div>
    </div>
  );
};

export default GsmGateDIStatus;
