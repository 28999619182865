import { topicType } from "../enums/topicType";
import { deviceStatusEventPublisher } from "../events/deviceStatusEventPublisher";
import config from "../config";
import { profileMatcher } from "../helpers/profileMatcher";
import { deviceApi } from "../api/deviceApi";
import { onlineStatus } from "../enums/onlineStatus";

export function Handle(e) {
  const textDecoder = new TextDecoder("utf-8");
  const decodedString = textDecoder.decode(e.payloadBytes);
  console.log(e.topic + " " + decodedString);
  let concreteMessageHandler = GetMessageHandlerBasedOnTopic(
    e.topic,
    config.mqttConfig.topics.subscribeTopics
  );

  if (concreteMessageHandler) {
    concreteMessageHandler({
      payloadString: decodedString,
      topic: e.topic
    });
  }
}

function getTopicWithoutId(topic) {
  const clientId = ExtractClientIdFromTopic(topic);
  if (profileMatcher.isGsmGate(clientId)) {
    const indexOfFirstSlash = topic.indexOf('/');
    return indexOfFirstSlash !== -1
      ? topic.substring(indexOfFirstSlash + 1)
      : topic;
  }

  return topic.split("/")[1];
}

function GetMessageHandlerBasedOnTopic(topic, subscribeTopics) {
  let topicWithoutId = getTopicWithoutId(topic);
  let matchedTopic = subscribeTopics.find((t) => topicWithoutId === t);
  let messageHandler;
  switch (matchedTopic) {
    case topicType.radioSilenceStatus:
      messageHandler = RadioSilenceStatusMessageHandler;
      break;
    case topicType.gsmAudioModeStatus:
      messageHandler = GsmAudioModeStatusMessageHandler;
      break;
    case topicType.radioAudioModeStatus:
      messageHandler = RadioAudioModeStatusMessageHandler;
      break;
    case topicType.scheduleStatus:
      messageHandler = ScheduleStatusMessageHandler;
      break;
    case topicType.batteryVoltageLevelStatus:
      messageHandler = BatteryVoltageLevelStatusMessageHandler;
      break;
    case topicType.gsm1SignalLevelStatus:
      messageHandler = Gsm1SignalLevelStatusMessageHandler;
      break;
    case topicType.gsm2SignalLevelStatus:
      messageHandler = Gsm2SignalLevelStatusMessageHandler;
      break;
    case topicType.gsm3SignalLevelStatus:
      messageHandler = Gsm3SignalLevelStatusMessageHandler;
      break;
    case topicType.onlineStatus:
      messageHandler = OnlineStatusMessageHandler;
      break;
    case topicType.lastWillStatus:
      messageHandler = LastWillStatusMessageHandler;
      break;
    case topicType.gsm1BalanceStatus:
      messageHandler = Gsm1BalanceStatusMessageHandler;
      break;
    case topicType.gsm2BalanceStatus:
      messageHandler = Gsm2BalanceStatusMessageHandler;
      break;
    case topicType.gsm3BalanceStatus:
      messageHandler = Gsm3BalanceStatusMessageHandler;
      break;
    case topicType.relayStatus:
      messageHandler = RelayStatusMessageHandler;
      break;
    case topicType.gsmGateGsmBalanceStatus:
      messageHandler = GsmGateGsmBalanceStatusMessageHandler;
      break;
    case topicType.gsmGateGsmSignalLevelStatus:
      messageHandler = GsmGateGsmSignalLevelStatusMessageHandler;
      break;
    case topicType.gsmGateDIStatus:
      messageHandler = GsmGateDIStatusMessageHandler;
      break;
    case topicType.gsmGatePhoneNumberStatus:
      messageHandler = GsmGatePhoneNumberStatusMessageHandler;
      break;
    case topicType.gsmGateOnlineStatus:
      messageHandler = GsmGateOnlineStatusMessageHandler;
      break;
    default:
      messageHandler = "";
  }

  return messageHandler;
}

function ExtractClientIdFromTopic(topic) {
  let strings = topic.split("/");
  let clientId = strings[0];
  return clientId;
}

function ParseDataFromPayload(payloadString) {
  payloadString = payloadString.replace(/ /g, "").toLowerCase();
  try {
    let strings = payloadString.split(";");
    let data = strings[0].split("data:")[1];
    let dateTime = strings[1].split("timestamp:")[1];
    return {
      data: data,
      dateTime: dateTime,
    };
  } catch (error) {
    console.error("Received incorrect data format of message from device.");
  }
}

function BuildEventContent(e) {
  const parsedPayload = ParseDataFromPayload(e.payloadString);
  let clientId = ExtractClientIdFromTopic(e.topic);
  let content = {
    clientId: clientId,
    topic: e.topic,
    data: parsedPayload.data,
    dateTime: parsedPayload.dateTime,
  };

  return content;
}

function BuildGsmGateEventContent(e) {
  let clientId = ExtractClientIdFromTopic(e.topic);
  let content = {
    clientId: clientId,
    topic: e.topic,
    data: e.payloadString
  };

  return content;
}

function RadioSilenceStatusMessageHandler(e) {
  try {
    let content = BuildEventContent(e);
    deviceStatusEventPublisher.publishRadioSilenceStatusChangedEvent(
      JSON.stringify(content)
    );

    const currentDateTime = new Date();
    const diffSeconds = Math.round(
      (currentDateTime.getTime() - Date.parse(content.dateTime)) /
        config.oneSecondInMilliseconds
    );
    
    if (diffSeconds < config.newMessageIndicatorSeconds) {
      deviceApi
        .createOrUpdateDeviceState({
          clientId: content.clientId,
          online: true,
        })
        .then((response) => {
          if (response.success) {
            content.dateTime = currentDateTime.getTime();
            content.data = true;

            deviceStatusEventPublisher.publishOnlineStatusChangedEvent(
              JSON.stringify(content)
            );
          }
        });
    }
  } catch (error) {
    console.error(
      "Received incorrect data format for RadioSilence Status from device."
    );
  }
}

function GsmAudioModeStatusMessageHandler(e) {
  try {
    let content = BuildEventContent(e);
    content.data = parseInt(content.data);
    deviceStatusEventPublisher.publishGsmAudioModeStatusChangedEvent(
      JSON.stringify(content)
    );

    const currentDateTime = new Date();
    const diffSeconds = Math.round((currentDateTime.getTime() - Date.parse(content.dateTime))/config.oneSecondInMilliseconds);
    if (diffSeconds < config.newMessageIndicatorSeconds) {
      deviceApi
        .createOrUpdateDeviceState({
          clientId: content.clientId,
          online: true,
        })
        .then((response) => {
          if (response.success) {
            content.data = true;
            content.dateTime = currentDateTime.toISOString();

            deviceStatusEventPublisher.publishOnlineStatusChangedEvent(
              JSON.stringify(content)
            );
          }
        });
    }
  } catch (error) {
    console.error(
      "Received incorrect data format for GsmAudioMode status from device."
    );
  }
}

function RadioAudioModeStatusMessageHandler(e) {
  try {
    let content = BuildEventContent(e);
    content.data = parseInt(content.data);
    deviceStatusEventPublisher.publishRadioAudioModeStatusChangedEvent(
      JSON.stringify(content)
    );

    const currentDateTime = new Date();
    const diffSeconds = Math.round((currentDateTime.getTime() - Date.parse(content.dateTime))/config.oneSecondInMilliseconds);
    if (diffSeconds < config.newMessageIndicatorSeconds) {
      deviceApi
        .createOrUpdateDeviceState({
          clientId: content.clientId,
          online: true,
        })
        .then((response) => {
          if (response.success) {
            content.data = true;
            content.dateTime = currentDateTime.toISOString();

            deviceStatusEventPublisher.publishOnlineStatusChangedEvent(
              JSON.stringify(content)
            );
          }
        });
    }
  } catch (error) {
    console.error(
      "Received incorrect data format for RadioAudioMode status from device."
    );
  }
}

function ScheduleStatusMessageHandler(e) {
  try {
    let content = BuildEventContent(e);
    deviceStatusEventPublisher.publishScheduleStatusChangedEvent(
      JSON.stringify(content)
    );

    const currentDateTime = new Date();
    const diffSeconds = Math.round((currentDateTime.getTime() - Date.parse(content.dateTime))/config.oneSecondInMilliseconds);
    if (diffSeconds < config.newMessageIndicatorSeconds) {
      deviceApi
        .createOrUpdateDeviceState({
          clientId: content.clientId,
          online: true,
        })
        .then((response) => {
          if (response.success) {
            content.data = true;
            content.dateTime = currentDateTime.toISOString();

            deviceStatusEventPublisher.publishOnlineStatusChangedEvent(
              JSON.stringify(content)
            );
          }
        });
    }
  } catch (error) {
    console.error(
      "Received incorrect data format for Schedule status from device."
    );
  }
}

function BatteryVoltageLevelStatusMessageHandler(e) {
  try {
    let content = BuildEventContent(e);
    content.data = parseFloat(content.data);
    deviceStatusEventPublisher.publishBatteryVoltageLevelStatusChangedEvent(
      JSON.stringify(content)
    );

    const currentDateTime = new Date();
    const diffSeconds = Math.round((currentDateTime.getTime() - Date.parse(content.dateTime))/config.oneSecondInMilliseconds);
    if (diffSeconds < config.newMessageIndicatorSeconds) {
      deviceApi
        .createOrUpdateDeviceState({
          clientId: content.clientId,
          online: true,
        })
        .then((response) => {
          if (response.success) {
            content.data = true;
            content.dateTime = currentDateTime.toISOString();

            deviceStatusEventPublisher.publishOnlineStatusChangedEvent(
              JSON.stringify(content)
            );
          }
        });
    }
  } catch (error) {
    console.error(
      "Received incorrect data format for BatteryVoltageLevel status from device."
    );
  }
}

function Gsm1SignalLevelStatusMessageHandler(e) {
  try {
    let content = BuildEventContent(e);
    content.data = parseInt(content.data);
    deviceStatusEventPublisher.publishGsm1SignalLevelStatusChangedEvent(
      JSON.stringify(content)
    );    

    const currentDateTime = new Date();
    const diffSeconds = Math.round((currentDateTime.getTime() - Date.parse(content.dateTime))/config.oneSecondInMilliseconds);
    if (diffSeconds < config.newMessageIndicatorSeconds) {
      deviceApi
        .createOrUpdateDeviceState({
          clientId: content.clientId,
          online: true,
        })
        .then((response) => {
          if (response.success) {
            content.data = true;
            content.dateTime = currentDateTime.toISOString();

            deviceStatusEventPublisher.publishOnlineStatusChangedEvent(
              JSON.stringify(content)
            );
          }
        });
    }
  } catch (error) {
    console.error(
      "Received incorrect data format for Gsm1SignalLevel status from device."
    );
  }
}

function Gsm2SignalLevelStatusMessageHandler(e) {
  try {
    let content = BuildEventContent(e);
    content.data = parseInt(content.data);
    deviceStatusEventPublisher.publishGsm2SignalLevelStatusChangedEvent(
      JSON.stringify(content)
    );

    const currentDateTime = new Date();
    const diffSeconds = Math.round((currentDateTime.getTime() - Date.parse(content.dateTime))/config.oneSecondInMilliseconds);
    if (diffSeconds < config.newMessageIndicatorSeconds) {
      deviceApi
        .createOrUpdateDeviceState({
          clientId: content.clientId,
          online: true,
        })
        .then((response) => {
          if (response.success) {
            content.data = true;
            content.dateTime = currentDateTime.toISOString();

            deviceStatusEventPublisher.publishOnlineStatusChangedEvent(
              JSON.stringify(content)
            );
          }
        });
    }
  } catch (error) {
    console.error(
      "Received incorrect data format for Gsm2SignalLevel status from device."
    );
  }
}

function Gsm3SignalLevelStatusMessageHandler(e) {
  try {
    let content = BuildEventContent(e);
    content.data = parseInt(content.data);
    deviceStatusEventPublisher.publishGsm3SignalLevelStatusChangedEvent(
      JSON.stringify(content)
    );

    const currentDateTime = new Date();
    const diffSeconds = Math.round((currentDateTime.getTime() - Date.parse(content.dateTime))/config.oneSecondInMilliseconds);
    if (diffSeconds < config.newMessageIndicatorSeconds) {
      deviceApi
        .createOrUpdateDeviceState({
          clientId: content.clientId,
          online: true,
        })
        .then((response) => {
          if (response.success) {
            content.data = true;
            content.dateTime = currentDateTime.toISOString();

            deviceStatusEventPublisher.publishOnlineStatusChangedEvent(
              JSON.stringify(content)
            );
          }
        });
    }
  } catch (error) {
    console.error(
      "Received incorrect data format for Gsm3SignalLevel status from device."
    );
  }
}

function Gsm1BalanceStatusMessageHandler(e) {
  try {
    let content = BuildEventContent(e);
    deviceStatusEventPublisher.publishGsm1BalanceStatusChangedEvent(
      JSON.stringify(content)
    );

    const currentDateTime = new Date();
    const diffSeconds = Math.round((currentDateTime.getTime() - Date.parse(content.dateTime))/config.oneSecondInMilliseconds);
    if (diffSeconds < config.newMessageIndicatorSeconds) {
      deviceApi
        .createOrUpdateDeviceState({
          clientId: content.clientId,
          online: true,
        })
        .then((response) => {
          if (response.success) {
            content.data = true;
            content.dateTime = currentDateTime.toISOString();

            deviceStatusEventPublisher.publishOnlineStatusChangedEvent(
              JSON.stringify(content)
            );
          }
        });
    }
  } catch (error) {
    console.error(
      "Received incorrect data format for Gsm1Balance status from device."
    );
  }
}

function Gsm2BalanceStatusMessageHandler(e) {
  try {
    let content = BuildEventContent(e);
    deviceStatusEventPublisher.publishGsm2BalanceStatusChangedEvent(
      JSON.stringify(content)
    );

    const currentDateTime = new Date();
    const diffSeconds = Math.round((currentDateTime.getTime() - Date.parse(content.dateTime))/config.oneSecondInMilliseconds);
    if (diffSeconds < config.newMessageIndicatorSeconds) {
      deviceApi
        .createOrUpdateDeviceState({
          clientId: content.clientId,
          online: true,
        })
        .then((response) => {
          if (response.success) {
            content.data = true;
            content.dateTime = currentDateTime.toISOString();

            deviceStatusEventPublisher.publishOnlineStatusChangedEvent(
              JSON.stringify(content)
            );
          }
        });
    }
  } catch (error) {
    console.error(
      "Received incorrect data format for Gsm2Balance status from device."
    );
  }
}

function Gsm3BalanceStatusMessageHandler(e) {
  try {
    let content = BuildEventContent(e);
    deviceStatusEventPublisher.publishGsm3BalanceStatusChangedEvent(
      JSON.stringify(content)
    );

    const currentDateTime = new Date();
    const diffSeconds = Math.round((currentDateTime.getTime() - Date.parse(content.dateTime))/config.oneSecondInMilliseconds);
    if (diffSeconds < config.newMessageIndicatorSeconds) {
      deviceApi
        .createOrUpdateDeviceState({
          clientId: content.clientId,
          online: true,
        })
        .then((response) => {
          if (response.success) {
            content.data = true;
            content.dateTime = currentDateTime.toISOString();

            deviceStatusEventPublisher.publishOnlineStatusChangedEvent(
              JSON.stringify(content)
            );
          }
        });
    }
  } catch (error) {
    console.error(
      "Received incorrect data format for Gsm2Balance status from device."
    );
  }
}

function RelayStatusMessageHandler(e) {
  try {
    let content = BuildEventContent(e);
    content.data = parseInt(content.data);
    deviceStatusEventPublisher.publishRelayStatusChangedEvent(
      JSON.stringify(content)
    );

    const currentDateTime = new Date();
    const diffSeconds = Math.round((currentDateTime.getTime() - Date.parse(content.dateTime))/config.oneSecondInMilliseconds);
    if (diffSeconds < config.newMessageIndicatorSeconds) {
      deviceApi
        .createOrUpdateDeviceState({
          clientId: content.clientId,
          online: true,
        })
        .then((response) => {
          if (response.success) {
            content.data = true;
            content.dateTime = currentDateTime.toISOString();

            deviceStatusEventPublisher.publishOnlineStatusChangedEvent(
              JSON.stringify(content)
            );
          }
        });
    }
  } catch (error) {
    console.error(
      "Received incorrect data format for Relay status from device."
    );
  }
}

function GsmGateGsmSignalLevelStatusMessageHandler(e) {
  try {
    const content = BuildGsmGateEventContent(e);
    content.data = parseInt(content.data);
    deviceStatusEventPublisher.publishGsmGateGsmSignalLevelStatusChangedEvent(
      JSON.stringify(content)
    );
  } catch (error) {
    console.error(
      "Received incorrect data format for GsmGateGsmSignalLevel status from device."
    );
  }
}

function GsmGateGsmBalanceStatusMessageHandler(e) {
  try {
    const content = BuildGsmGateEventContent(e);
    content.data = parseFloat(content.data);
    deviceStatusEventPublisher.publishGsmGateGsmBalanceStatusChangedEvent(
      JSON.stringify(content)
    );    
  } catch (error) {
    console.error(
      "Received incorrect data format for GsmGateGsmBalance status from device."
    );
  }
}

function GsmGateDIStatusMessageHandler(e) {
  try {
    const content = BuildGsmGateEventContent(e);
    deviceStatusEventPublisher.publishGsmGateDIStatusChangedEvent(
      JSON.stringify(content)
    );
  } catch (error) {
    console.error(
      "Received incorrect data format for GsmGateDI status from device."
    );
  }
}

function GsmGatePhoneNumberStatusMessageHandler(e) {
  try {
    const content = BuildGsmGateEventContent(e);
    deviceStatusEventPublisher.publishGsmGatePhoneNumberStatusChangedEvent(
      JSON.stringify(content)
    );
  } catch (error) {
    console.error(
      "Received incorrect data format for GsmGateUserPhoneNumber status from device."
    );
  }
}

function GsmGateOnlineStatusMessageHandler(e) {
  try {
    const content = BuildGsmGateEventContent(e);
    deviceStatusEventPublisher.publishGsmGateOnlineStatusChangedEvent(
      JSON.stringify(content)
    );

    deviceApi.createOrUpdateDeviceState({
      clientId: content.clientId,
      online: content.data === onlineStatus.online ? true : false,
    });
  } catch (error) {
    console.error(
      "Received incorrect data format for GsmGateUserPhoneNumber status from device."
    );
  }
}

function LastWillStatusMessageHandler(e) {
  try {
    let clientId = ExtractClientIdFromTopic(e.topic);
    deviceStatusEventPublisher.publishLastWillStatusChangedEvent(
      JSON.stringify({
        clientId: clientId,
        data: false
      })
    );

    deviceApi.createOrUpdateDeviceState({
      clientId: clientId,
      online: false
    });
  } catch (error) {
    console.error(
      "Received incorrect data format for LastWill status from device."
    );
  }
}

function OnlineStatusMessageHandler(e) {
  try {
    let content = BuildEventContent(e);
    const currentDateTime = new Date();
    const diffSeconds = Math.round((currentDateTime.getTime() - Date.parse(content.dateTime))/config.oneSecondInMilliseconds);
    if (diffSeconds < config.newMessageIndicatorSeconds) {
      deviceApi
        .createOrUpdateDeviceState({
          clientId: content.clientId,
          online: true,
        })
        .then((response) => {
          if (response.success) {
            content.data = true;
            content.dateTime = currentDateTime.toISOString();

            deviceStatusEventPublisher.publishOnlineStatusChangedEvent(
              JSON.stringify(content)
            );
          }
        });
    }
  } catch (error) {
    console.error(
      "Received incorrect data format for Online status from device."
    );
  }
}