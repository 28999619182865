import OneBarGsmSignalLevel from "./OneBarGsmSignalLevel";
import TwoBarGsmSignalLevel from "./TwoBarGsmSignalLevel";
import ThreeBarGsmSignalLevel from "./ThreeBarGsmSignalLevel";
import FourBarGsmSignalLevel from "./FourBarGsmSignalLevel";
import FiveBarGsmSignalLevel from "./FiveBarGsmSignalLevel";
import NoSignalGsmSignalLevel from "./NoSignalGsmSignalLevel";
import { deviceStatusType } from "../../../../enums/deviceStatus";
import React from "react";

const GsmSignalLevelPicker = ({ gsmSignalLevel }) => {
  const renderGsmSignalLevel = (gsmSignalLevel) => {
    gsmSignalLevel = parseInt(gsmSignalLevel);
    
    if (isNaN(gsmSignalLevel) || gsmSignalLevel === 99)
      return <span>{deviceStatusType.notApplicable}</span>;

    if (gsmSignalLevel > 30 && gsmSignalLevel < 99)
      return <FiveBarGsmSignalLevel />;

    if (gsmSignalLevel > 24 && gsmSignalLevel <= 30)
      return <FourBarGsmSignalLevel />;

    if (gsmSignalLevel > 17 && gsmSignalLevel <= 24)
      return <ThreeBarGsmSignalLevel />;

    if (gsmSignalLevel > 10 && gsmSignalLevel <= 17)
      return <TwoBarGsmSignalLevel />;

    if (gsmSignalLevel >= 3 && gsmSignalLevel <= 10)
      return <OneBarGsmSignalLevel />;

    if (gsmSignalLevel < 3)
      return <NoSignalGsmSignalLevel />;
  };

  return renderGsmSignalLevel(gsmSignalLevel);
};

export default GsmSignalLevelPicker;
