import BatteryVoltageLevelStatusUpdater from "./profiles/imitator/BatteryVoltageLevelStatusUpdater";
import GsmSignalLevelStatusUpdater from "./profiles/imitator/GsmSignalLevelStatusUpdater";
import GsmAudioModeStatusUpdater from "./profiles/imitator/GsmAudioModeStatusUpdater";
import RadioAudioModeStatusUpdater from "./profiles/imitator/RadioAudioModeStatusUpdater";
import RadioSilenceStatusUpdater from "./profiles/imitator/RadioSilenceStatusUpdater";
import ScheduleStatusUpdater from "./profiles/imitator/ScheduleStatusUpdater";
import GsmBalanceStatusUpdater from "./profiles/imitator/GsmBalanceStatusUpdater";
import RelayStatusUpdater from "./profiles/wifi/RelayStatusUpdater";
import GsmGateGsmSignalLevelStatusUpdater from "./profiles/gsm-gate/GsmGateGsmSignalLevelStatusUpdater";
import GsmGateGsmBalanceStatusUpdater from "./profiles/gsm-gate/GsmGateGsmBalanceStatusUpdater";
import GsmGateDIStatusUpdater from "./profiles/gsm-gate/GsmGateDIStatusUpdater";
import GsmGatePhoneNumberStatusUpdater from "./profiles/gsm-gate/GsmGatePhoneNumberStatusUpdater";
import GsmGateOnlineStatusUpdater from "./profiles/gsm-gate/GsmGateOnlineStatusUpdater";
import OnlineStatusUpdater from "./profiles/imitator/OnlineStatusUpdater";
import LastWillStatusUpdater from "./profiles/imitator/LastWillStatusUpdater";

const DeviceStatusUpdater = ({ setDeviceList }) => {
  return (
    <>
      <BatteryVoltageLevelStatusUpdater setDeviceList={setDeviceList} />
      <GsmSignalLevelStatusUpdater setDeviceList={setDeviceList} />
      <RadioSilenceStatusUpdater setDeviceList={setDeviceList} />
      <GsmAudioModeStatusUpdater setDeviceList={setDeviceList} />
      <RadioAudioModeStatusUpdater setDeviceList={setDeviceList} />
      <ScheduleStatusUpdater setDeviceList={setDeviceList} />
      <GsmBalanceStatusUpdater setDeviceList={setDeviceList} />
      <RelayStatusUpdater setDeviceList={setDeviceList} />
      <RelayStatusUpdater setDeviceList={setDeviceList} />      
      <GsmGateGsmSignalLevelStatusUpdater setDeviceList={setDeviceList} />
      <GsmGateGsmBalanceStatusUpdater setDeviceList={setDeviceList} />
      <GsmGateDIStatusUpdater setDeviceList={setDeviceList} />
      <GsmGatePhoneNumberStatusUpdater setDeviceList={setDeviceList} />
      <GsmGateOnlineStatusUpdater setDeviceList={setDeviceList} />      
      <OnlineStatusUpdater setDeviceList={setDeviceList} />
      <LastWillStatusUpdater setDeviceList={setDeviceList} />
    </>
  );
};

export default DeviceStatusUpdater;
