import { useEffect, useState } from "react";
import classes from "../../css/pages/devices/devices.module.css";
import { deviceApi } from "../../../api/deviceApi";
import AddDevice from "./AddDevice";
import DeviceRow from "./DeviceRow";
import DeviceHeader from "./DeviceHeader";
import { devicesDictionary } from "../../../dictionary/ua/devicesDictionary";
import { documentTitleDictionary } from "../../../dictionary/ua/documentTitleDictionary";
import { useNavigate } from "react-router-dom";
import { links } from "../../../enums/links";
import config from "../../../config";
import { refreshPage } from "../../../helpers/windowOperations";
import useDocumentTitle from "../../hooks/useDocumentTitle";

const Devices = ({ cookies }) => {
  useDocumentTitle(documentTitleDictionary.devicesTitle + " - " + documentTitleDictionary.mainTitle);
  
  const navigate = useNavigate();
  const [deviceList, setDeviceList] = useState([]);
  const isAuthenticated = () => {
    return cookies.get("token")?.token ? true : false;
  }

  const isAdmin = () => {
    return cookies.get("token")?.groupname === "Admins" ? true : false;
  }

  const fetchDeviceList = () => {
    deviceApi
      .getAllByUserId(getUserId())
      .then((response) => {
        let mappedDevices = response.map((d) => ({
          ...d,
          toDelete: false,
        }));
        setDeviceList(mappedDevices);
      })
      .catch(function (error) {
         //log error
      });
  };

  const getUserId = () => {
    return cookies.get("token").userId;
  }

  useEffect(() => {
    if (isAuthenticated) fetchDeviceList();
    const interval = setInterval(() => {
      if (!isAuthenticated()) {
        navigate(links.loginPagePath);
        refreshPage();
      }
    }, config.checkCookieExpirationMilliseconds);

    return () => {
      clearInterval(interval);
    }
  }, []);

  return (
    isAuthenticated() && (
      <>
        <div className={classes["main-container"]}>
          <div className={classes["add-device-container"]}>
            <AddDevice setDeviceList={setDeviceList} userId={getUserId()} />
          </div>
          {deviceList.length > 0 ? (
            <>
              <div className={classes["header-container"]}>
                <div className={classes["aside-for-header"]}></div>
                <div className={classes["device-header"]}>
                  <DeviceHeader
                    deviceList={deviceList}
                    setDeviceList={setDeviceList}
                    isAdmin={isAdmin()}
                  />
                </div>
                <div className={classes["aside-for-header"]}></div>
              </div>

              <div className={classes["body-container"]}>
                <div className={classes["aside-for-body"]}></div>
                <div className={classes["device-row"]}>
                  <div className={classes["rows-container"]}>
                    {deviceList.map((device) => (
                      <DeviceRow
                        key={device.id}
                        device={device}
                        setDeviceList={setDeviceList}
                        isAdmin={isAdmin()}
                      />
                    ))}
                  </div>
                </div>
                <div className={classes["aside-for-body"]}></div>
              </div>
            </>
          ) : (
            <h2>{devicesDictionary.noAvailableDeviceText}</h2>
          )}
        </div>
      </>
    )
  );
};

export default Devices;
