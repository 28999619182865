import BatteryVoltageLevelStatus from "../../../../statuses/profiles/imitator/BatteryVoltageLevelStatus";
import GsmStatus from "../../../../statuses/profiles/imitator/GsmStatus";
import GsmAudioModeStatus from "../../../../statuses/profiles/imitator/GsmAudioModeStatus";
import RadioAudioModeStatus from "../../../../statuses/profiles/imitator/RadioAudioModeStatus";
import RadioSilenceStatus from "../../../../statuses/profiles/imitator/RadioSilenceStatus";
import DeviceStatusUpdater from "../../../../deviceStatusUpdaters/DeviceStatusUpdater";
import classes from "../../../../css/pages/devices-list/profiles/imitator/deviceRow.module.css";
import { Tooltip } from "@mui/material";
import ScheduleStatus from "../../../../statuses/profiles/imitator/ScheduleStatus";
import LastWillStatus from "../../../../statuses/profiles/imitator/LastWillStatus";
import { devicesListDictionary } from "../../../../../dictionary/ua/devicesListDictionary";
import OnlineStatus from "../../../../statuses/profiles/imitator/OnlineStatus";
import { useEffect } from "react";
import config from "../../../../../config";
import { deviceApi } from "../../../../../api/deviceApi";
import { deviceStatusEventPublisher } from "../../../../../events/deviceStatusEventPublisher";
import { getDifferenceInSecondsBetweenCurrentUtcTimeStamp } from "../../../../../helpers/dayJsHelper";
import { gsmDataHelper } from "../../../../../helpers/gsmDataHelper";
import { dateTimeHelper } from "../../../../../helpers/dateTimeHelper";
import { deviceExtensionHelper } from "../../../../../helpers/deviceExtensionHelper";

const DeviceRow = ({ device, setDeviceList }) => {
  const getTooltipTitle = (dateTime) => {
    if (!device.online && !isNaN(Date.parse(dateTime))) {
      const allDateTimes = deviceExtensionHelper.getAllDateTimes(device);
      dateTime = dateTimeHelper.getLatestDateTime(allDateTimes);
      return `${devicesListDictionary.lastOnlineStatusText} ${dateTimeHelper.getLocalDateTime(dateTime)}`;
    }

    return "";
  };

  const handleSelectDeviceClick = () => {
    setDeviceList((prevState) => {
      const foundDevice = Object.keys(prevState).reduce((found, profile) => {
        const dev = prevState[profile].array.find((d) => d.clientId === device.clientId);
        return dev ? { profile, device } : found;
      }, null);

      if (foundDevice) {
        const { profile } = foundDevice;
        return {
          ...prevState,
          [profile]: {
            array: prevState[profile].array.map((d) =>
              d.clientId === device.clientId
                ? { ...d, selectedForSendingMessage: !d.selectedForSendingMessage }
                : d
            ),
          },
        };
      }

      return prevState;
    });
  };

  useEffect(() => {
    const interval = setInterval(() => {     
      const diffSeconds = getDifferenceInSecondsBetweenCurrentUtcTimeStamp(device.deviceStateUpdatedDateTimeUtc);
     if (diffSeconds > config.updateToOfflineStatusTimeoutSeconds) {
        deviceApi.createOrUpdateDeviceState({
          clientId: device.clientId,
          online: false,
        }).then((response) => {
          if (response.success) {
            deviceStatusEventPublisher.publishOnlineStatusChangedEvent(
              JSON.stringify({
                data: false,
                dateTime: device.deviceStateUpdatedDateTimeUtc,
                clientId: device.clientId,
              })
            );
          }
        }).catch((error) => {
          console.error("Error updating device state:", error);
        });
      }
    }, config.checkOnlineStatusIntervalMilliseconds);

    return () => {
      clearInterval(interval);
    };
  }, [device.deviceStateUpdatedDateTimeUtc, device.clientId]);

  return (
    <Tooltip
      title={getTooltipTitle(device.deviceStateUpdatedDateTimeUtc)}
      arrow
      placement="right"
    >
      <div className={`${classes["row-wrapper"]}`}>
        <DeviceStatusUpdater setDeviceList={setDeviceList} />
        <>
          <LastWillStatus clientId={device.clientId} />
          <OnlineStatus clientId={device.clientId} />
        </>
        <div className={`${classes["wrapped-area"]} ${classes[deviceExtensionHelper.getOnlineStatusClass(device.online)]}`}>
        <div className={classes["name"]}>{device.name}</div>
          <div className={classes["row"]}>
            <div className={`${classes["column"]} ${classes["radio-silence-column"]}`}>
              <RadioSilenceStatus device={device} />
            </div>
            <div className={`${classes["column"]} ${classes["gsm-audio-mode-column"]}`}>
              <GsmAudioModeStatus device={device} />
            </div>
            <div className={`${classes["column"]} ${classes["radio-audio-mode-column"]}`}>
              <RadioAudioModeStatus device={device} />
            </div>
            <div className={`${classes["column"]} ${classes["gsm-column"]}`}>
              <GsmStatus clientId={device.clientId} gsmDataList={gsmDataHelper.getGsmDataListByProfile(device)} />
            </div>
            <div className={`${classes["column"]} ${classes["battery-level-column"]}`}>
              <BatteryVoltageLevelStatus device={device} />
            </div>
            <div className={`${classes["column"]} ${classes["schedule-column"]}`}>
              <ScheduleStatus device={device} />
            </div>
            <div className={`${classes["column"]} ${classes["select-device-column"]}`}>
              <div className={classes["select-device-checkbox-wrapper"]}>
                <label htmlFor="select-device-checkbox">Вибрати</label>
                <input
                  id="select-device-checkbox"
                  type="checkbox"
                  className={classes["select-device-checkbox"]}
                  onChange={handleSelectDeviceClick}
                  checked={device.selectedForSendingMessage}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Tooltip>
  );
};

export default DeviceRow;