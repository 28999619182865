export const mqttConnectDictionary = {
    hostLabel: "Хост",
    portLabel: "Порт",
    usernameLabel: "Логін",
    passwordLabel: "Пароль",
    tlsLabel: "Безпечне з'єднання: ",
    connectToMqttButtonText: "З'єднати",
    connectedToMqttText: "З'єднано",
    notConnectedToMqttText: "Не з'єднано",    
    capsLockIsOnText: "Увімкнено CapsLock",
    disconnectedFromMqttText: "Ви не під'єднані до сервера. Підключіться будь ласка.",
    saveCurrentMqttSettings: "Зберегти поточні налаштування",
    restoreDefaultMqttSettings: "Відновити стандартні налаштування"
}