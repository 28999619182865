import { useEffect } from "react";
import { Connect } from "../../mqtt/mqttService";
import config from "../../config";
import { mqttSettingsApi } from "../../api/mqttSettingsApi";
import { mqttConnectionStatus } from "../../enums/mqttConnectionStatus";
import { useMqttConnectionContext } from "../context/AppContext";
import { mqttConnectionLostEventType } from "../../enums/eventType";
import { publish } from "../../events/event";
import { errorType } from "../../enums/errorType";

const useConnectToMqtt = (userId, isAuthenticated) => {
  const { connectionStatus, updateConnectionStatus } = useMqttConnectionContext();

  const onConnectedHandler = (e) => {
    updateConnectionStatus(mqttConnectionStatus.isConnected);
  };

  const onConnectionLostHandler = (e) => {
    publish(mqttConnectionLostEventType.mqttConnectionLost);
    updateConnectionStatus(mqttConnectionStatus.isNotConnected);
  };

  const onErrorHandler = (error) => {
    switch (error) {
      case errorType.mqttConnectionError:
        updateConnectionStatus(mqttConnectionStatus.isNotConnected);
        break;
      default:
    }
  };

  const isConnectedToMqtt = connectionStatus === mqttConnectionStatus.isConnected;
  const connectToMqtt = () => {
    if (!isConnectedToMqtt && isAuthenticated) {
      mqttSettingsApi.getByUserId(userId).then((response) => {
        try {
          const connectAsync = async () => {
            await Connect(
              response.host,
              parseInt(response.port),
              config.mqttConfig.myClientId,
              config.mqttConfig.cleanSession,
              onErrorHandler,
              onConnectedHandler,
              onConnectionLostHandler
            ).then(() => {
              updateConnectionStatus(mqttConnectionStatus.isConnecting);
            });
          };

          connectAsync();
        } catch (error) {
          onErrorHandler(error);
        }
      });
    }
  };

  useEffect(() => {
    connectToMqtt();        
    const interval = setInterval(() => {
     connectToMqtt();
    }, config.reconnectToMqttMilliSeconds);

    return () => {
      clearInterval(interval);
    };
  }, []);
};

export default useConnectToMqtt;
