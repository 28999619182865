import React, { useEffect, useState } from "react";
import classes from "../../css/pages/login/login.module.css";
import { loginSchema } from "../../../validation/validationSchemas";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { authenticationDictionary } from "../../../dictionary/ua/authenticationDictionary";
import { documentTitleDictionary } from "../../../dictionary/ua/documentTitleDictionary";
import {
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  OutlinedInput,
} from "@mui/material";

import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import visibilityIconClasses from "../../css/assets/icons/visibilityIcon.module.css";
import Button from "../../assets/buttons/Button";
import buttonClasses from "../../css/assets/buttons/button.module.css";
import PlusIcon from "../../assets/icons/PlusIcon";
import { authApi } from "../../../api/authApi";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { links } from "../../../enums/links";
import config from "../../../config";
import { authenticationErrorMapper } from "../../../enums/errorMappers/authenticationErrorMapper";
import { refreshPage } from "../../../helpers/windowOperations";
import useDocumentTitle from "../../hooks/useDocumentTitle";

function Login() {
  useDocumentTitle(documentTitleDictionary.loginTitle + " - " + documentTitleDictionary.mainTitle);
  const [apiErrors, setApiErrors] = useState([]);
  const [cookies, setCookies] = useCookies();
  const [disabled, setDisabled] = useState(true);
  const navigate = useNavigate();
  const [pressed, setPressed] = useState(false);
  const [showPassword, setValues] = useState(false);
  const [isCapsLockOn, setIsCapsLockOn] = useState(false);
  const [loginForm, setLoginForm] = useState({
    username: "",
    password: "",
  });

  const {
    register,
    handleSubmit,
    getFieldState,
    reset,
    formState: { errors, isValid, isDirty, isSubmitSuccessful },
  } = useForm({
    resolver: yupResolver(loginSchema),
    mode: "onChange",
  });

  const onSubmit = async (data) => {
    setLoginForm({
      username: data.username,
      password: data.password,
    });

    let payload = {
      username: data.username,
      password: data.password
    };   

    authApi.login(payload).then((response) => {
      if (response.success === true) {
        setCookies("token", JSON.stringify(response.result), {
          maxAge: config.cookieSettings.maxAgeMinutes * 60,
        });

        navigate(links.devicesPath);
        refreshPage();
      }
      else {     
        var errorsCodesAndErrorTexts = response.errors.map((e) => {
          return {
            errorCode: e.errorCode,
            errorText: authenticationErrorMapper[e.errorCode],
          };
        });

        setApiErrors(errorsCodesAndErrorTexts);
      }
    }); 
  };

  const handleClickShowPassword = () => {
    setValues((prevState) => !prevState);
  };

  const checkCapsLock = (event) => {
    if (event.getModifierState("CapsLock")) {
      setIsCapsLockOn(true);
    } else {
      setIsCapsLockOn(false);
    }
  };

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset(
        { username: loginForm.username, password: loginForm.password },
        { keepDefaultValues: true }
      );
    }
  }, [isSubmitSuccessful, reset]);

  const setPressedClass = () => {
    return pressed === true ? "pressed" : "";
  };

  const handleMouseDown = () => {
    setPressed(true);
    document.addEventListener("mouseup", () => handleMouseUp(), { once: true });
  };

  const handleMouseUp = () => {
    setPressed(false);
  };

  const handleKeyDown = () => {
    setApiErrors([]);
  }

  return (
    <div>
      <h3>{authenticationDictionary.pleaseAuthenticateText}</h3>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={classes["form-control-container"]}>
          <span>
            <label htmlFor="username">
              {authenticationDictionary.usernameLabel}
            </label>
          </span>
          <span className={classes["input-container"]}>
            <FormControl
              className={classes["form-control"]}
              error={getFieldState("username").invalid}
            >
              <OutlinedInput
                id="username"
                type="text"
                name="username"
                size="small"
                {...register("username")}
                onKeyDown={handleKeyDown}
              />
              <FormHelperText>{errors?.username?.message}</FormHelperText>
            </FormControl>
          </span>
        </div>

        <div className={classes["form-control-container"]}>
          <span>
            <label htmlFor="password">
              {authenticationDictionary.passwordLabel}
            </label>
          </span>
          <span className={classes["input-container"]}>
            <FormControl
              className={classes["form-control"]}
              error={getFieldState("password").invalid}
            >
              <OutlinedInput
                id="password"
                type={showPassword ? "text" : "password"}
                name="password"
                size="small"
                {...register("password")}
                onKeyUp={checkCapsLock}
                onKeyDown={handleKeyDown}
                endAdornment={
                  <InputAdornment
                    className={classes["input-adornment"]}
                    position="end"
                  >
                    <IconButton onClick={handleClickShowPassword}>
                      {showPassword ? (
                        <div
                          className={
                            visibilityIconClasses["visibility-icon-wrapper"]
                          }
                        >
                          <VisibilityIcon
                            className={visibilityIconClasses["visibility-icon"]}
                          />
                        </div>
                      ) : (
                        <div
                          className={
                            visibilityIconClasses["visibility-icon-wrapper"]
                          }
                        >
                          <VisibilityOffIcon
                            className={visibilityIconClasses["visibility-icon"]}
                          />
                        </div>
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
              <FormHelperText>{errors?.password?.message}</FormHelperText>
              {apiErrors.map((e) => (
                <FormHelperText key={e.errorCode}>{e.errorText}</FormHelperText>
              ))}
            </FormControl>
          </span>
          <span>
            <FormHelperText
              sx={{ color: "#ff9966" }}
              className={classes["warning-text"]}
            >
              {isCapsLockOn && (
                <span>{authenticationDictionary.capsLockIsOnText}</span>
              )}
            </FormHelperText>
          </span>
        </div>

        <Button
          className={`${buttonClasses["login-button"]} ${
            buttonClasses[setPressedClass()]
          }`}
          disabled={!isValid}
          icon={<PlusIcon />}
          startIcon="start"
          onMouseDown={handleMouseDown}
          onMouseUp={handleMouseUp}
        >
          {authenticationDictionary.loginButtonText}
        </Button>
      </form>
    </div>
  );
}

export default Login;
