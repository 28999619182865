import classes from "../../../css/pages/scheduling/commands/repeatNTimes.module.css";
import buttonClasses from "../../../css/assets/buttons/button.module.css";
import Button from "../../../assets/buttons/Button";
import { FormControl, FormHelperText, InputLabel, OutlinedInput, Tooltip } from "@mui/material";
import { useState } from "react";
import { repeatOption, repeatOptionLabels } from "../../../../enums/scheduling/repeatOption";
import { scheduleDesigner } from "../../../../dictionary/ua/scheduling/scheduleDesigner";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { repeatNTimesSchema } from "../../../../validation/validationSchemas";
import { onceOption, onceOptionLabels } from "../../../../enums/scheduling/onceOption";
import { tooltipHelper } from "../../../../helpers/tooltipHelper";
import { tooltips } from "../../../../enums/tooltips";

const RepeatNTimes = ({ insertAtCursor }) => {
  const [times, setTimes] = useState("");
  const {
    register,
    handleSubmit,
    getFieldState,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(repeatNTimesSchema),
    mode: "onChange",
  });

  const isError = () => {
    return getFieldState("times").invalid;
  }

  const onSubmit = () => {
    insertAtCursor(repeatOptionLabels[repeatOption.repeatNTimes](times), repeatOption.repeatNTimes);
  };

  const handleChange = (event) => {
    setTimes(event.target.value);
  };

  return (
    <div className={classes["container"]}>
      <form className={classes["form"]} onSubmit={handleSubmit(onSubmit)}>
        <Button className={`${buttonClasses["repeat-n-times-button"]}`}>
          {scheduleDesigner.repeatNTimesButtonText}
        </Button>
        <div className={classes["input-container"]}>
          <FormControl
            className={classes["form-control"]}
            error={isError()}
            onChange={handleChange}
          >
            <OutlinedInput
              type="text"
              size="small"
              value={times}
              {...register("times")}
            />
            <InputLabel htmlFor="times">{scheduleDesigner.numberOfRepetitionsLabelText}</InputLabel>
            <FormHelperText>{errors?.times?.message}</FormHelperText>
          </FormControl>
        </div>
        <div className={classes["information"]}>
          {scheduleDesigner.repeatNtimesInformationText}
        </div>
      </form>
    </div>
  );
};

export default RepeatNTimes;
