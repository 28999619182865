import classes from "../../../css/pages/scheduling/commands/setRadioChannelForBothTransceivers.module.css";
import buttonClasses from "../../../css/assets/buttons/button.module.css";
import Button from "../../../assets/buttons/Button"
import { FormControl, FormHelperText, InputLabel, OutlinedInput } from "@mui/material";
import { useState } from "react";
import { setRadioChannelForTransceiverOption, setRadioChannelForTransceiverOptionLabels } from "../../../../enums/scheduling/setRadioChannelForTransceiverOption";
import { scheduleDesigner } from "../../../../dictionary/ua/scheduling/scheduleDesigner";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { setRadioChannelForBothTransceiversSchema } from "../../../../validation/validationSchemas";

const SetRadioChannelForBothTransceivers = ({ insertAtCursor }) => {
  const [channel, setChannel] = useState("");
  const {
    register,
    handleSubmit,
    getFieldState,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(setRadioChannelForBothTransceiversSchema),
    mode: "onChange",
  });

  const isError = () => {
    return getFieldState("channel").invalid;
  }
  
  const onSubmit = () => {
    insertAtCursor(setRadioChannelForTransceiverOptionLabels[setRadioChannelForTransceiverOption.setRadioChannelForBothTransceivers](channel));
  };

  const handleChange = (event) => {
    setChannel(event.target.value);
  };

  return (
    <div className={classes["container"]}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Button
          className={`${buttonClasses["set-radio-channel-for-both-transceivers-button"]}`}
        >
          {scheduleDesigner.setRadioChannelForBothTransceiverButtonText}
        </Button>
        <div className={classes["input-container"]}>
          <FormControl
            className={classes["form-control"]}
            error={isError()}
            onChange={handleChange}
          >
            <OutlinedInput
              type="text"
              size="small"
              value={channel}
              {...register("channel")}
            />
            <InputLabel htmlFor="channel">{scheduleDesigner.radioChannelLabelText}</InputLabel>
             <FormHelperText>{errors?.channel?.message}</FormHelperText>
          </FormControl>
        </div>
        <div className={classes["information"]}>
        </div>
      </form>
    </div>
  );
};

export default SetRadioChannelForBothTransceivers;
