import { useEffect } from "react";
import { deviceStatusEventType } from "../../../../enums/eventType";
import { subscribe, unsubscribe } from "../../../../events/event";
import config from "../../../../config";

const RadioSilenceStatusUpdater = ({ setDeviceList }) => {  
    const updateDeviceStatus = (data) => {
    const parsed = JSON.parse(data.detail);
    const currentDateTime = new Date();
    const diffMinutes = Math.round((currentDateTime.getTime() - Date.parse(parsed.dateTime))/config.oneMinuteInMilliseconds);
    let newStatus = parsed.data - diffMinutes;
    if(newStatus < 0) {
      newStatus = 0;
    }

    const lastUpdated = new Date(parsed.dateTime);

    setDeviceList((prevState) => {
      const foundDevice = Object.keys(prevState).reduce((found, profile) => {
        const device = prevState[profile].array.find(
          (d) => d.clientId === parsed.clientId
        );
        return device ? { profile, device } : found;
      }, null);

      if (foundDevice) {
        const { profile } = foundDevice;
        return {
          ...prevState,
          [profile]: {
            array: prevState[profile].array.map((d) =>
              d.clientId === parsed.clientId
                ? {
                    ...d,
                    radioSilenceStatus: newStatus,
                    radioSilenceStatusLastUpdated: lastUpdated,
                    deviceStateUpdatedDateTimeUtc: lastUpdated,
                  }
                : d
            ),
          },
        };
      }
    });
  };

  useEffect(() => {
    subscribe(
      deviceStatusEventType.radioSilenceStatusChanged,
      updateDeviceStatus
    );
    
    return () => {
      unsubscribe(
        deviceStatusEventType.radioSilenceStatusChanged,
        updateDeviceStatus
      );
    };
  }, []);

  return <></>;
};

export default RadioSilenceStatusUpdater;
