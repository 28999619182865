import { useEffect } from "react";
import { deviceStatusEventType } from "../../../../enums/eventType";
import { subscribe, unsubscribe } from "../../../../events/event";

const GsmSignalLevelStatusUpdater = ({ setDeviceList }) => {
  const updateGsm1SignalLevelStatus = (data) => {
    const parsed = JSON.parse(data.detail);
    const newStatus = parsed.data;
    const lastUpdated = new Date(parsed.dateTime);

    setDeviceList((prevState) => {
      const foundDevice = Object.keys(prevState).reduce((found, profile) => {
        const device = prevState[profile].array.find(
          (d) => d.clientId === parsed.clientId
        );
        return device ? { profile, device } : found;
      }, null);

      if (foundDevice) {
        const { profile } = foundDevice;
        return {
          ...prevState,
          [profile]: {
            array: prevState[profile].array.map((d) =>
              d.clientId === parsed.clientId
                ? {
                    ...d,
                    gsm1SignalLevelStatus: newStatus,
                    gsm1SignalLevelStatusLastUpdated: lastUpdated,
                    deviceStateUpdatedDateTimeUtc: lastUpdated,
                  }
                : d
            ),
          },
        };
      }
    });
  };

  const updateGsm2SignalLevelStatus = (data) => {
    const parsed = JSON.parse(data.detail);
    const newStatus = parsed.data;
    const lastUpdated = new Date(parsed.dateTime);

    setDeviceList((prevState) => {
      const foundDevice = Object.keys(prevState).reduce((found, profile) => {
        const device = prevState[profile].array.find(
          (d) => d.clientId === parsed.clientId
        );
        return device ? { profile, device } : found;
      }, null);

      if (foundDevice) {
        const { profile, device } = foundDevice;
        return {
          ...prevState,
          [profile]: {
            array: prevState[profile].array.map((d) =>
              d.clientId === parsed.clientId
                ? {
                    ...d,
                    gsm2SignalLevelStatus: newStatus,
                    gsm2SignalLevelStatusLastUpdated: lastUpdated,
                    lastUpdated: lastUpdated,
                  }
                : d
            ),
          },
        };
      }
    });
  };

  const updateGsm3SignalLevelStatus = (data) => {
    const parsed = JSON.parse(data.detail);
    const newStatus = parsed.data;
    const lastUpdated = new Date(parsed.dateTime);

    setDeviceList((prevState) => {
      const foundDevice = Object.keys(prevState).reduce((found, profile) => {
        const device = prevState[profile].array.find(
          (d) => d.clientId === parsed.clientId
        );
        return device ? { profile, device } : found;
      }, null);

      if (foundDevice) {
        const { profile, device } = foundDevice;
        return {
          ...prevState,
          [profile]: {
            array: prevState[profile].array.map((d) =>
              d.clientId === parsed.clientId
                ? {
                    ...d,
                    gsm3SignalLevelStatus: newStatus,
                    gsm3SignalLevelStatusLastUpdated: lastUpdated,
                    lastUpdated: lastUpdated,
                  }
                : d
            ),
          },
        };
      }
    });
  };

  useEffect(() => {
    subscribe(
      deviceStatusEventType.gsm1SignalLevelStatusChanged,
      updateGsm1SignalLevelStatus
    );

    subscribe(
      deviceStatusEventType.gsm2SignalLevelStatusChanged,
      updateGsm2SignalLevelStatus
    );

    subscribe(
      deviceStatusEventType.gsm3SignalLevelStatusChanged,
      updateGsm3SignalLevelStatus
    );
    
    return () => {
      unsubscribe(
        deviceStatusEventType.gsm1SignalLevelStatusChanged,
        updateGsm1SignalLevelStatus
      );

      unsubscribe(
        deviceStatusEventType.gsm2SignalLevelStatusChanged,
        updateGsm2SignalLevelStatus
      );

      unsubscribe(
        deviceStatusEventType.gsm3SignalLevelStatusChanged,
        updateGsm3SignalLevelStatus
      );
    };
  }, []);

  return <></>;
};

export default GsmSignalLevelStatusUpdater;
