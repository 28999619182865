import React, { createContext, useContext, useState } from "react";
import { mqttConnectionStatus } from "../../enums/mqttConnectionStatus";

const mqttConnectionContext = createContext("isNotConnected");
export const useMqttConnectionContext = () => useContext(mqttConnectionContext);

const AppContext = ({ children }) => {
  const [connectionStatus, setConnectionStatus] = useState(mqttConnectionStatus.isNotConnected);
  const updateConnectionStatus = (status) => {
    setConnectionStatus(status);
  };
  return (
    <mqttConnectionContext.Provider
      value={{ connectionStatus, updateConnectionStatus }}
    >
      {children}
    </mqttConnectionContext.Provider>
  );
};

export default AppContext;
