import { axiosClient } from "../axios/axiosConfig";

export const mqttSettingsApi = {
  getByUserId: async function (userId) {
    const response = await axiosClient.request({
      url: `/mqttsettings/userId?userId=${userId}`,
      method: "GET",
    });

    return response.data;
  },
  update: async function (mqttSettings) {
    const response = await axiosClient.request({
      url: `/mqttsettings/${mqttSettings.id}`,
      method: "PUT",
      data: mqttSettings,
    });

    return response.data;
  },
  restoreDefaultSettingsByUserId: async function (userId) {
    const response = await axiosClient.request({
      url: `/mqttsettings/restore?userId=${userId}`,
      method: "PUT",
    });

    return response.data;
  },
};
