import { useEffect } from "react";
import classes from "../../../css/deviceStatus.module.css";
import { useMqttConnectionContext } from "../../../context/AppContext";
import { topicType } from "../../../../enums/topicType";
import { Subscribe } from "../../../../mqtt/mqttService";
import { mqttConnectionStatus } from "../../../../enums/mqttConnectionStatus";
import config from "../../../../config";
import { getTopicName } from "../../../../helpers/getTopicName";
import { deviceStatusType } from "../../../../enums/deviceStatus";
import { devicesListDictionary } from "../../../../dictionary/ua/devicesListDictionary";
import { Tooltip } from "@mui/material";
import { scheduleResponseMapper } from "../../../../helpers/scheduleResponseMapper";

const ScheduleStatus = ({ device }) => {
  const { connectionStatus } = useMqttConnectionContext();
  const getTooltipTitle = (date) => {
    if(!isNaN(Date.parse(date))) {
      return `${devicesListDictionary.lastUpdatedStatusText} ${date.toLocaleString(config.localization.ua)}`;
    }
    
    return "";
  }

  useEffect(() => {
    if (connectionStatus === mqttConnectionStatus.isConnected) {
      setTimeout(() => {
        Subscribe(getTopicName(device.clientId, topicType.scheduleStatus), 1);
      }, config.subscribeTimeoutMilliSeconds);
    }
  }, [device.clientId, connectionStatus]);

  const prepareData = (data) => {
    const splitted = data.split(":")[1];
    if(splitted) {
      return splitted?.replace(/\s/g, "").toUpperCase();
    }

    return data?.replace(/\s/g, "").toUpperCase();
  };

  return (
    <Tooltip
      title={getTooltipTitle(device.scheduleStatusLastUpdated)}
      arrow
      placement="bottom"
    >
      <div className={classes["device-status-wrapper"]}>
        {device.scheduleStatus !== deviceStatusType.notApplicable ? (
          scheduleResponseMapper(prepareData(device.scheduleStatus)) || prepareData(device.scheduleStatus)
        ) : (
          <>{deviceStatusType.notApplicable}</>
        )}
      </div>
    </Tooltip>
  );
};

export default ScheduleStatus;
