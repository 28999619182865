import React from "react";
import { deviceProfile } from "../../../enums/deviceProfile";
import ImitatorControlPanel from "./profiles/imitator/ControlPanel";
import WifiControlPanel from "./profiles/wifi/ControlPanel";
import GsmGateControlPanel from "./profiles/gsm-gate/ControlPanel";

const profileControlPanelMap = {
  [deviceProfile.imitator]: ImitatorControlPanel,
  [deviceProfile.wifi]: WifiControlPanel,
  [deviceProfile.gsmGate]: GsmGateControlPanel
};

const ControlPanelPicker = ({
  profile,
  selectedDevicesClientIds,
  setDeviceList,
  scheduleList,
  selectAllDevicesCheckboxIndicator,
}) => {
  const ControlPanelComponent = profileControlPanelMap[profile] || ImitatorControlPanel;

  return (
    <ControlPanelComponent
      profile={profile}
      selectedDevicesClientIds={selectedDevicesClientIds[profile]}
      setDeviceList={setDeviceList}
      scheduleList={scheduleList}
      selectAllDevicesCheckboxIndicator={selectAllDevicesCheckboxIndicator}
    />
  );
};

export default ControlPanelPicker;
