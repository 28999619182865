import React from "react";
import { deviceProfile } from "../../../enums/deviceProfile";
import ImitatorDeviceHeader from "./profiles/imitator/DeviceHeader";
import WifiDeviceHeader from "./profiles/wifi/DeviceHeader";
import GsmGateDeviceHeader from "./profiles/gsm-gate/DeviceHeader";

const profileHeaderMap = {
  [deviceProfile.imitator]: ImitatorDeviceHeader,
  [deviceProfile.wifi]: WifiDeviceHeader,
  [deviceProfile.gsmGate]: GsmGateDeviceHeader
};

const DeviceHeaderPicker = ({ profile, props }) => {
  const HeaderComponent = profileHeaderMap[profile] || ImitatorDeviceHeader;
  return <HeaderComponent {...props} />;
};

export default DeviceHeaderPicker;
