import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { deleteScheduleDialogDictionary } from "../../../dictionary/ua/scheduling/deleteScheduleDialogDictionary";
import { DialogContent } from "@mui/material";
import UListForSchedules from "../lists/UListForSchedules";

function DeleteSchedulesDialog(props) {
  const { onClose, open, schedules } = props;
  const handleClose = (selectedToDelete) => {
    onClose(selectedToDelete);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="delete-schedule-dialog-title"
      >
        <DialogTitle id="delete-schedule-dialog-title">
          {deleteScheduleDialogDictionary.dialogQuestionDeleteSchedulesText}
        </DialogTitle>
        <DialogContent>
          <UListForSchedules items={schedules} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose(true)}>
            {deleteScheduleDialogDictionary.dialogYesResponseText}
          </Button>
          <Button onClick={() => handleClose(false)}>
            {deleteScheduleDialogDictionary.dialogNoResponseText}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default DeleteSchedulesDialog;
