import classes from "../../../css/pages/scheduling/commands/waitForNSeconds.module.css";
import buttonClasses from "../../../css/assets/buttons/button.module.css";
import Button from "../../../assets/buttons/Button";
import { FormControl, FormHelperText, InputLabel, OutlinedInput } from "@mui/material";
import { useState } from "react";
import { waitDurationOption, waitDurationOptionLabels } from "../../../../enums/scheduling/waitDurationOption";
import { scheduleDesigner } from "../../../../dictionary/ua/scheduling/scheduleDesigner";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { waitForNSecondsSchema } from "../../../../validation/validationSchemas";

const WaitForNSeconds = ({ insertAtCursor }) => {
  const [seconds, setSeconds] = useState("");
  const {
    register,
    handleSubmit,
    getFieldState,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(waitForNSecondsSchema),
    mode: "onChange",
  });

  const isError = () => {
    return getFieldState("seconds").invalid;
  }
  
  const onSubmit = () => {
    insertAtCursor(waitDurationOptionLabels[waitDurationOption.forNSeconds](seconds));
  };

  const handleChange = (event) => {
    setSeconds(event.target.value);
  };

  return (
    <div className={classes["container"]}>
      <form className={classes["form"]} onSubmit={handleSubmit(onSubmit)}>
        <Button className={`${buttonClasses["wait-for-n-seconds-button"]}`}>
          {scheduleDesigner.waitForNSecondsButtonText}
        </Button>
        <div className={classes["input-container"]}>
          <FormControl
            className={classes["form-control"]}
            error={isError()}
            onChange={handleChange}
          >
            <InputLabel htmlFor="seconds">{scheduleDesigner.nSecondsLabelText}</InputLabel>
            <OutlinedInput
              type="text"
              size="small"
              value={seconds}
              label="seconds"
              {...register("seconds")}
            />
            <FormHelperText>{errors?.seconds?.message}</FormHelperText>
            <label htmlFor="seconds">{scheduleDesigner.waitForNSecondsInformationText}</label>
          </FormControl>
        </div>
        <div className={classes["information"]}>
          {scheduleDesigner.repeatNTimesButtonInformationText}
        </div>
      </form>
    </div>
  );
};

export default WaitForNSeconds;
