import classes from "../../../css/pages/scheduling/commands/dialFromNGsmToPhoneNumber.module.css";
import buttonClasses from "../../../css/assets/buttons/button.module.css";
import Button from "../../../assets/buttons/Button";
import { FormControl, FormHelperText, InputLabel, MenuItem, OutlinedInput, Select } from "@mui/material";
import { useState } from "react";
import { dialOption, dialOptionLabels } from "../../../../enums/scheduling/dialOption";
import { scheduleDesigner } from "../../../../dictionary/ua/scheduling/scheduleDesigner";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { dialFromNGsmToPhoneNumberSchema } from "../../../../validation/validationSchemas";
import { gsmModuleNumbers } from "../../../../enums/gsmModuleNumbers";

const DialFromNGsmToPhoneNumber = ({ insertAtCursor }) => {
  const [inputData, setInputData] = useState({
    gsmModule: "",
    phoneNumber: "",
  });

  const {
    register,
    handleSubmit,
    getFieldState,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(dialFromNGsmToPhoneNumberSchema),
    mode: "onChange",
  });

  const isError = () => {
    return getFieldState("gsmModule").invalid || getFieldState("phoneNumber").invalid;
  }

  const onSubmit = () => {
    insertAtCursor(
      dialOptionLabels[dialOption.dialFromNGsmToPhoneNumber](
        inputData.gsmModule,
        inputData.phoneNumber
      )
    );
  };

  const handleChange = (event) => {
    setInputData((prevState) => {
      return {
        ...prevState,
        [event.target.name]: event.target.value,
      };
    });
  };

  return (
    <div className={classes["container"]}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Button
          className={`${buttonClasses["dial-from-n-gsm-to-phone-number-button"]}`}
        >
          {scheduleDesigner.dialFromNGsmToPhoneNumberButtonText}
        </Button>
        <div className={classes["phone-number-container"]}>
          <FormControl
            className={classes["form-control"]}
            error={isError()}
            onChange={handleChange}
          >
            <InputLabel htmlFor="phoneNumber">{scheduleDesigner.phoneNumberSampleLabelText}</InputLabel>
            <OutlinedInput
              type="text"
              size="small"
              value={inputData.phoneNumber}
              name="phoneNumber"
              {...register("phoneNumber")}
            />
            <FormHelperText>{errors?.phoneNumber?.message}</FormHelperText>
          </FormControl>
        </div>
        <div className={classes["gsm-module-container"]}>
          <FormControl className={classes["form-control"]} error={isError()}>
            <Select
              id="gsmModule"
              className={classes["gsm-audio-mode-select"]}
              value={inputData.gsmModule}
              size="small"
              {...register("gsmModule")}
              onChange={handleChange}
            >
              {gsmModuleNumbers.map((gm) => 
                <MenuItem key={gm} value={gm}>{gm}</MenuItem>
              )}
            </Select>
            <FormHelperText>{errors?.gsmModule?.message}</FormHelperText>
          </FormControl>
        </div>
        <div className={classes["information"]}>
          {scheduleDesigner.dialFromNGsmToPhoneNumberInformationText}
        </div>
      </form>
    </div>
  );
};

export default DialFromNGsmToPhoneNumber;
