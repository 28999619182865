import React, { useState, useEffect } from "react";
import { deviceApi } from "../../../api/deviceApi";
import { documentTitleDictionary } from "../../../dictionary/ua/documentTitleDictionary";
import { useNavigate } from "react-router-dom";
import { links } from "../../../enums/links";
import config from "../../../config";
import { refreshPage } from "../../../helpers/windowOperations";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import classes from "../../css/pages/scheduling/scheduling.module.css";
import useConnectToMqtt from "../../hooks/useConnectToMqtt";
import { scheduleApi } from "../../../api/scheduleApi";
import AddSchedule from "./AddSchedule";
import ScheduleBuilder from "./ScheduleBuilder";
import OwnSchedules from "./schedules-list/own-schedules/OwnSchedules";
import PublicSchedules from "./schedules-list/public-schedules/PublicSchedules";

const Scheduling = ({ cookies }) => {
  useDocumentTitle(documentTitleDictionary.schedulingTitle + " - " + documentTitleDictionary.mainTitle);

  const navigate = useNavigate();
  const [deviceList, setDeviceList] = useState([]);
  const [ownedScheduleList, setOwnedScheduleList] = useState([]);
  const [sharedScheduleList, setSharedScheduleList] = useState([]);
  const [scheduleString, setScheduleString] = useState('');

  const isAuthenticated = () => {
    return cookies.get("token")?.token ? true : false;
  };

  const getUserId = () => {
    return cookies.get("token")?.userId;
  };

  const getUsername = () => {
    return cookies.get("token")?.username;
  };

  const fetchDeviceList = () => {
    deviceApi
      .getAllByUserId(getUserId())
      .then((response) => {
        setDeviceList(response);
      })
      .catch(function (error) {
        //log error
      });
  };

  const fetchOnlyOwnedScheduleList = (search) => {
    scheduleApi
      .getOnlyOwnedByUser(getUsername(), search)
      .then((response) => {
        let mappedSchedules = response.map((s) => ({
          ...s,
          toDelete: false,
        }));

        setOwnedScheduleList(mappedSchedules);
      })
      .catch(function (error) {
        // log error
      });
  };

  const fetchSharedScheduleList = (search) => {
    scheduleApi
      .getOnlySharedForUser(getUsername(), search)
      .then((response) => {
        let mappedSchedules = response.map((s) => ({
          ...s,
          toDelete: false,
        }));

        setSharedScheduleList(mappedSchedules);
      })
      .catch(function (error) {
        //log error
      });
  };

  useConnectToMqtt(getUserId(), isAuthenticated());

  useEffect(() => {
    if (isAuthenticated()) {
      fetchDeviceList();
      fetchOnlyOwnedScheduleList("");
      fetchSharedScheduleList("");
    }

    const interval = setInterval(() => {
      if (!isAuthenticated()) {
        navigate(links.loginPagePath);
        refreshPage();
      }
    }, config.checkCookieExpirationMilliseconds);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    isAuthenticated() && (
      <div className={classes["main-container"]}>
        <div className={classes["schedule-builder-wrapper"]}>
          <div className={classes["schedule-builder-container"]}>
            <ScheduleBuilder
              scheduleString={scheduleString}
              setScheduleString={setScheduleString}
            />
          </div>
          <div className={classes["add-schedule-container"]}>
            <AddSchedule
              scheduleString={scheduleString}
              username={getUsername()}
              setScheduleList={setOwnedScheduleList}
            />
          </div>
        </div>

        <div className={classes["schedules-list-container"]}>
          <div className={classes["own-schedules-list-container"]}>
            <OwnSchedules
              scheduleList={ownedScheduleList}
              setScheduleList={setOwnedScheduleList}
              username={getUsername()}
            />
          </div>

          <div className={classes["shared-schedules-list-container"]}>
            <PublicSchedules
              scheduleList={sharedScheduleList}
              setOwnedScheduleList={setOwnedScheduleList}
              setSharedScheduleList={setSharedScheduleList}
              username={getUsername()}
            />
          </div>
        </div>
      </div>
    )
  );
};

export default Scheduling;
