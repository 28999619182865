import React, { useEffect, useState } from "react";
import classes from "../css/commands/radioSilenceCommand.module.css";
import { Publish } from "../../mqtt/mqttService";
import { useMqttConnectionContext } from "../context/AppContext";
import { topicType } from "../../enums/topicType";
import { mqttConnectionStatus } from "../../enums/mqttConnectionStatus";
import { getTopicName } from "../../helpers/getTopicName";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { radioSilenceCommandInputSchema } from "../../validation/validationSchemas";
import { FormControl, FormHelperText, OutlinedInput } from "@mui/material";
import Button from "../assets/buttons/Button";
import RadioSilenceIcon from "../assets/icons/RadioSilenceIcon";
import buttonClasses from "../css/assets/buttons/button.module.css";
import { mqttConnectDictionary } from "../../dictionary/ua/mqttConnectDictionary";
import { devicesListDictionary } from "../../dictionary/ua/devicesListDictionary";

function RadioSilenceCommand({ selectedDevicesClientIds }) {
  const [notValidationErrorText, setNotValidationError] = useState("");
  const [pressed, setPressed] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [radioSilenceMinutes, setRadioSilenceMinutes] = useState("");
  const { connectionStatus } = useMqttConnectionContext();
  const {
    register,
    handleSubmit,
    getFieldState,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(radioSilenceCommandInputSchema),
    mode: "onChange",
  });

  const onSubmit = (data) => {
    if (connectionStatus !== mqttConnectionStatus.isConnected) {
      setNotValidationError(mqttConnectDictionary.disconnectedFromMqttText);
      return;
    } else {
      setNotValidationError("");
    }

    setRadioSilenceMinutes("");
    setDisabled(true);
    selectedDevicesClientIds.map(clientId => {
      Publish(
        getTopicName(clientId, topicType.radioSilenceCommand),
        data.radioSilenceMinutes,
        1,
        false,
        false
      );

      return clientId;
    });   
  };

  const setPressedClass = () => {
    return pressed === true ? "pressed" : "";
  };

  const handleMouseDown = () => {
    setPressed(true);
    document.addEventListener("mouseup", () => handleMouseUp(), { once: true });
  };

  const handleMouseUp = () => {
    setPressed(false);
  };

  const handleChange = (event) => {    
    if (radioSilenceMinutes && isAnyDeviceSelected()) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }

    setRadioSilenceMinutes(event.target.value);
  };

  const isError = () => {
    return getFieldState("gsmAudioMode").invalid || notValidationErrorText !== "";
  }

  const isAnyDeviceSelected = () => selectedDevicesClientIds?.length > 0;

  useEffect(() => {    
    setDisabled(radioSilenceMinutes === "" || !isAnyDeviceSelected());
}, [isAnyDeviceSelected]);

  return (
    <div className={classes.command}>
      <span>{devicesListDictionary.radioSilenceInputLabel}</span>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={classes["input-container"]}>
          <FormControl
            className={classes["form-control"]}
            error={isError()}
            onChange={handleChange}
          >
            <OutlinedInput
              id="radioSilenceMinutes"
              type="number"
              name="radioSilenceMinutes"
              size="small"
              value={radioSilenceMinutes}
              {...register("radioSilenceMinutes")}
            />
            <FormHelperText>
              {errors?.radioSilenceMinutes?.message}
            </FormHelperText>
          </FormControl>
          <FormHelperText className={classes["not-validation-error-text"]}>{notValidationErrorText}</FormHelperText>
          <Button
            className={`${buttonClasses["radio-silence-button"]} ${
              buttonClasses[setPressedClass()]
            }`}
            disabled={disabled}
            icon={<RadioSilenceIcon />}
            startIcon="start"
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
          >
            {devicesListDictionary.radioSilenceButtonText}
          </Button>
        </div>
      </form>
    </div>
  );
}

export default RadioSilenceCommand;
