import { addDeviceDictionary } from "../dictionary/ua/addDeviceDictionary";

export const deviceProfile = {
  imitator: "imitator",
  wifi: "wifi",
  gsmGate: "gsmGate",
};

export const deviceProfileMapper = {
  imitator: addDeviceDictionary.imitatorProfileText,
  wifi: addDeviceDictionary.wifiProfileText,
  gsmGate: addDeviceDictionary.gsmGateProfileText,
};
