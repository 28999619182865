import { useEffect } from "react";
import classes from "../../../css/deviceStatus.module.css";
import { Subscribe } from "../../../../mqtt/mqttService";
import { useMqttConnectionContext } from "../../../context/AppContext";
import { topicType } from "../../../../enums/topicType";
import { mqttConnectionStatus } from "../../../../enums/mqttConnectionStatus";
import config from "../../../../config";
import { getTopicName } from "../../../../helpers/getTopicName";
import convertVoltageLevelToPercentage from "../../../../helpers/batteryVoltageLevelConverter";
import { deviceStatusType } from "../../../../enums/deviceStatus";
import { devicesListDictionary } from "../../../../dictionary/ua/devicesListDictionary";
import { Tooltip } from "@mui/material";

const BatteryVoltageLevelStatus = ({ device }) => {
  const { connectionStatus } = useMqttConnectionContext();
  const getTooltipTitle = (date) => {
    if(!isNaN(Date.parse(date))) {
      return `${devicesListDictionary.lastUpdatedStatusText} ${date.toLocaleString(config.localization.ua)}`;
    }
    
    return "";
  }

  useEffect(() => {
    if (connectionStatus === mqttConnectionStatus.isConnected) {
      setTimeout(() => {
        Subscribe(
          getTopicName(device.clientId, topicType.batteryVoltageLevelStatus),
          1
        );
      }, config.subscribeTimeoutMilliSeconds);
    }
  }, [device.clientId, connectionStatus]);

  return (
    <Tooltip
      title={getTooltipTitle(device.batteryVoltageLevelStatusLastUpdated)}
      arrow
      placement="bottom"
    >
      <div className={classes["device-status-wrapper"]}>
        <div>
          {device.batteryVoltageLevelStatus !==
          deviceStatusType.notApplicable ? (
            <>
              {convertVoltageLevelToPercentage(
                device.batteryVoltageLevelStatus
              )}
              %
            </>
          ) : (
            <>{deviceStatusType.notApplicable}</>
          )}
        </div>
      </div>
    </Tooltip>
  );
};

export default BatteryVoltageLevelStatus;
